angular.module('site.controllers')
    .controller('voucherDetailsController', ['$scope', '$http', '$timeout', '$location', function ($scope, $http, $timeout, $location) {

        var vm = this;
        $scope.siteId;
        $scope.displayTargetsInput = false;
        $scope.displayTargetsPreview = false;
        $scope.targetPreviewList = [];

        $scope.targetListType = null;
        $scope.showPercent = false;

        $scope.init = function () {
            $scope.updateTargetsInputDisplay();

            // Initiate ng-model for siteId input - minor hack (angular dont like the form being populated from serverside)
            if (document.querySelector('#hiddenSiteId') !== null) {
                $scope.siteId = parseInt(document.querySelector('#hiddenSiteId').value);
            }
            else if (document.querySelector("#selectSiteId") !== null) {
                $scope.siteId = parseInt(document.querySelector("#selectSiteId").selectedIndex = 1);
            }
        };

        $scope.submit = function (frm) {
            console.log("submit", frm);
            frm.submit();
        };

        $scope.initEscapedTargetsFromDataAttribute = function () {
            var targets = $('#Targets').attr('data-targets');
            console.log(targets);
            $scope.voucherTargets = (targets || '').toString();
        };

        $scope.updateTargetsInputDisplay = function () {
            var voucherTypesWithTarget = [1, 3, 5, 6];
            $scope.displayTargetsInput = voucherTypesWithTarget.indexOf(parseInt($scope.voucherTypeId)) !== -1;

            var voucherType = parseInt($scope.voucherTypeId);
            $scope.targetListType = null;

            if (voucherType === 1 || voucherType === 3) {
                $scope.targetListType = "products";
            } else if (voucherType === 5 || voucherType === 6) {
                $scope.targetListType = "brands";
            }
        };


        $scope.updatePercentSignDisplay = function () {
            var voucherTypesWithTarget = [1, 2, 5];

            $scope.showPercent = voucherTypesWithTarget.indexOf(parseInt($scope.voucherTypeId)) !== -1;
        };

        $scope.lookupVoucherTargetItems = function() {
            var targetIds = $scope.voucherTargets;

            var voucherTypesWithTarget = [1, 3, 5, 6];
            if (voucherTypesWithTarget.indexOf(parseInt($scope.voucherTypeId)) === -1) {
                return;
            }

            // Would be nice to get these from the Sites db-table
            var searchIndexes = {
                '1': "w", //webapoteket
                '2': "m" //webvelvære
            };

            var domainIndexes = {
                '1': "searchapi.webapoteket.dk", //webapoteket
                '2': "search.webvelvære.dk" //webvelvære
            };

            var siteIndexId = searchIndexes[$scope.siteId];
            var lookupType = $scope.targetListType === "products" ? "productlookup" : "brandlookup";

            var domain = domainIndexes[$scope.siteId];
            var protocol = $location.protocol();
            var url = protocol +
                "://" +
                domain +
                "/voucher/" +
                lookupType +
                "/" +
                siteIndexId +
                "/?targets=" +
                targetIds;

            $http.get(url)
                .then(function(resp) {
                    console.log(resp.data);
                    if (resp.data !== null && resp.data.length > 0) {
                        $scope.targetPreviewList = resp.data;
                        $scope.displayTargetsPreview = true;
                    } else {
                        $scope.targetPreviewList = [];
                        $scope.displayTargetsPreview = false;
                    }
                },function (response) {
                    $scope.targetPreviewList = [];
                    $scope.displayTargetsPreview = false;
                    alert("Kan ikke hente rabatter.");
                    console.log("Kan ikke hente rabatter.", response);
                });

        };

    }]);
