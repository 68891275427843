(function (angular) {
    'use strict';
    angular.module('site.services')
        .factory('changelogservice', [ '$http','$q', function ($http, $q) {

            return {
                searchPaged : function (orderNumber, data) {
                    var deferred = $q.defer();
                    return $http
                        .post('/api/changelog/' + orderNumber +'/searchpaged', JSON.stringify(data))
                        .then(function (response) {
                            deferred.resolve(response.data);
                            return deferred.promise;
                        }, function (response) {
                            deferred.reject(response);
                            return deferred.promise;
                        });
                }}
        }]);
})(window.angular);

