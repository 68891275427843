//angular.module('site.controllers', ['angularjs-dropdown-multiselect'])
angular.module('site.controllers')
//angular.module('site.controllers', ['btorfs.multiselect'])
    .controller('voucherListController', ['$scope', '$http', '$timeout', '$location', function($scope, $http, $timeout, $location) {

        var vm = this;

        $scope.init = function () {
            $('#filterMarketingGroup').multiselect();
            $('#filterMarketingDiscountType').multiselect();

            vm.pagination = {};
            vm.pagination["itemsPerPage"] = 25;
            vm.pagination["totalItemCount"] = 0;

            $scope.paginationCurrentPage = 1;
            $scope.paginationTotalPages = 1;
            $scope.paginationPages = new Array(1);

            $scope.includeInvalid = false;
            $scope.includeDeleted = false;

            $scope.updateList($scope.paginationCurrentPage);

            loadMarketingGroups();
            loadMarketingDiscountTypes();
        };

        function loadMarketingGroups() {
            var url = "/adn/vouchersapi/MarketingGroups/";
            $http.get(url)
                .then(function (data) {
                    var selectElement = $('#filterMarketingGroup');
                    if (data) {
                        for (var i = 0; i < data.length; i++) {
                            var marketingGroup = data[i];

                            // Cannot get the bootstrap-multiselect to populate options from angular-repeater, so we append options manually
                            if (selectElement.length > 0) {
                                selectElement.append("<option value='" + marketingGroup.MarketingGroupId + "'>" + marketingGroup.Name + "</option>");
                            }
                        }
                    }
                    $('#filterMarketingGroup').multiselect('rebuild');

                },function (response) {
                    alert("Kan ikke hente rabatter.");
                    console.log("Kan ikke hente rabatter.", response);
                });
        }

        function loadMarketingDiscountTypes() {
            var url = "/vouchersapi/MarketingDiscountTypes";
            $http.get(url)
               .then(function (resp) {
                   var selectElement = $('#filterMarketingDiscountType');
                   if (resp && resp.data) {
                       for (var i = 0; i < resp.data.length; i++) {
                           var discountType = resp.data[i];

                           // Cannot get the bootstrap-multiselect to populate options from angular-repeater, so we append options manually
                           if (selectElement.length > 0) {
                               selectElement.append("<option value='" + discountType.MarketingDiscountTypeId + "'>" + discountType.Name + "</option>");
                           }
                       }
                   }
                   $('#filterMarketingDiscountType').multiselect('rebuild');

               },function (response) {
                   alert("Kan ikke hente rabattyper.");
                   console.log("Kan ikke hente rabattyper.", response);
               });
        }

        function loadVouchers() {
            var url = "/vouchersapi/VoucherList/";

            var fromItem = (($scope.paginationCurrentPage - 1) * vm.pagination["itemsPerPage"]) + 1;
            var toItem = $scope.paginationCurrentPage * vm.pagination["itemsPerPage"];

            url += "?fromRow=" + fromItem + "&toRow=" + toItem;

            var filterMarketingGroupIds = parseMultiSelectInput($scope.filterMarketingGroup);
            var filterMarketingDiscountTypeIds = parseMultiSelectInput($scope.filterMarketingDiscountType);

            if ($scope.filterName)
                url += "&filterName=" + $scope.filterName;
            if ($scope.filterFromDate)
                url += "&filterFromDate=" + $scope.filterFromDate;
            if ($scope.filterToDate)
                url += "&filterToDate=" + $scope.filterToDate;
            if (filterMarketingGroupIds && filterMarketingGroupIds.length > 0)
                url += "&filterMarketingGroup=" + JSON.stringify(filterMarketingGroupIds);
            if (filterMarketingDiscountTypeIds && filterMarketingDiscountTypeIds.length > 0)
                url += "&filterMarketingDiscountTypeId=" + JSON.stringify(filterMarketingDiscountTypeIds);

            if ($scope.includeDeleted)
                url += "&includeDeleted=true";

            if ($scope.includeInvalid)
                url += "&includeInvalid=true";



            $http.get(url)
                .then(function (resp) {
                    $scope.vouchers = mapVouchers(resp.data);
                    $scope.pendingSearch = false;

                    // Use setTimeout to let Angular render list before applying popover
                    setTimeout(function() {
                        $('a.dropdown-toggle').popover({ container: 'body' });
                    }, 400);

                },function (response) {
                    $scope.pendingSearch = false;
                    alert("Kan ikke hente rabatter.");
                    console.log("Kan ikke hente rabatter.", response);
                });
        }


        $scope.updateList = function (currentPageNumber) {

            $scope.paginationCurrentPage = currentPageNumber;

            var filterMarketingGroupIds = [];
            if ($scope.filterMarketingGroup && $scope.filterMarketingGroup.length > 0) {
                $scope.filterMarketingGroup.forEach(function(id) {
                    filterMarketingGroupIds.push(parseInt(id));
                });
            }

            var filterMarketingGroupIds = parseMultiSelectInput($scope.filterMarketingGroup);
            var filterMarketingDiscountTypeIds = parseMultiSelectInput($scope.filterMarketingDiscountType);

            var url = "/vouchersapi/VoucherCount/?";
            if ($scope.filterName)
                url += "&filterName=" + $scope.filterName;
            if ($scope.filterFromDate)
                url += "&filterFromDate=" + $scope.filterFromDate;
            if ($scope.filterToDate)
                url += "&filterToDate=" + $scope.filterToDate;
            if (filterMarketingGroupIds && filterMarketingGroupIds.length > 0)
                url += "&filterMarketingGroup=" + JSON.stringify(filterMarketingGroupIds);
            if (filterMarketingDiscountTypeIds && filterMarketingDiscountTypeIds.length)
                url += "&filterMarketingDiscountTypeId=" + JSON.stringify(filterMarketingDiscountTypeIds);

            $http.get(url).then(function (resp) {
                vm.pagination["totalItemCount"] = parseInt(resp.data);

                $scope.paginationTotalPages = Math.ceil(vm.pagination["totalItemCount"] / vm.pagination["itemsPerPage"]);
                $scope.paginationPages = new Array($scope.paginationTotalPages);

                loadVouchers();

            },function (response) {
                alert("Fejl.");
                console.log("Fejl.", response);
            });

        }

        function parseMultiSelectInput(idsAsStringList) {
            var idsAsIntList = [];
            if (idsAsStringList && idsAsStringList.length > 0) {
                idsAsStringList.forEach(function (id) {
                    idsAsIntList.push(parseInt(id));
                });
            }
            return idsAsIntList;
        }

        function mapVouchers(data) {
            var list = [];
            if (data) {
                for (var i = 0; i < data.length; i++) {
                    var voucher = data[i];
                    var element = {};
                    element.VoucherId = voucher.VoucherId;
                    element.Name = voucher.Name;
                    element.MarketingGroupId = voucher.MarketingGroupId;
                    element.MarketingGroupName = voucher.MarketingGroupName;
                    element.MarketingDiscountTypeId = voucher.MarketingDiscountTypeId;
                    element.MarketingDiscountTypeName = voucher.MarketingDiscountTypeName;
                    element.VoucherTypeId = voucher.VoucherTypeId;
                    element.VoucherTypeName = voucher.VoucherTypeName;
                    element.SiteId = voucher.SiteId;
                    element.SiteName = voucher.SiteName;
                    element.Value = voucher.Value;
                    element.MinOrderAmount = voucher.MinOrderAmount; //Todo
                    element.ValidFrom = voucher.ValidFrom;
                    element.ValidTo = voucher.ValidTo;
                    element.Comment = voucher.Comment ? voucher.Comment.replace(/(?:\r\n|\r|\n)/g, '<br />') : ""; //html-linebreaks in comments
                    element.IsValid = voucher.IsValid;
                    element.Deleted = voucher.Deleted;

                    list.push(element);
                }
            }
            return list;
        }

    }]);
