(function (angular) {
    'use strict';
    angular.module('site.directives')
        .directive('productsSelect', function () {
            return {
                restrict: 'E',
                transclude: true,
                scope: {
                    informationId:"=",
                    genericAtcId:"=",
                    markedExisting:"=",
                    refreshProducts:"=",
                    isEditable:"=",
                    loadData:"="
                },
                templateUrl: '/Layout/js/html/products-select.html',
                controller: ['$scope', '$compile', '$location','productinformationservice','genericatcexceptedproductinformationservice','informationproductservice', 'DTOptionsBuilder', 'DTColumnBuilder', 'DTColumnDefBuilder',
                    function ($scope, $compile, $location, productinformationservice,genericatcexceptedproductinformationservice,informationproductservice, DTOptionsBuilder, DTColumnBuilder, DTColumnDefBuilder) {

                        $.fn.dataTable.ext.errMode = 'none';

                        $scope.loadingProducts = false;

                        $scope.dtInstanceProduct = {};

                        $scope.dtColumnsProduct = [
                            DTColumnBuilder.newColumn(null).renderWith(renderOptions).notSortable(),
                            DTColumnBuilder.newColumn("Id", "ID").withOption('name', 'Id').notVisible(),
                            DTColumnBuilder.newColumn("Sku", "Vare nr.").withOption('name', 'Sku'),
                            DTColumnBuilder.newColumn("Name", "Navn").withOption('name', 'Name'),
                            DTColumnBuilder.newColumn("AtcCode", "ATC kode").withOption('name', 'AtcCode'),
                            DTColumnBuilder.newColumn("DrugId", "Drug ID").withOption('name', 'DrugId')
                        ];

                        function renderOptions(data, type, full, meta)
                        {
                            if(data && !$scope.isEditable)
                            {
                                return '<p ng-if="' + data.Exists + '">' +
                                            '<button class="btn btn-danger" ng-click="remove(' + data.Id + ')">' +
                                                '<i class="glyphicon glyphicon-remove"></i>&nbsp;Fjern' +
                                            '</button>&nbsp;' +
                                        '</p>'+
                                        '<p ng-if="!' + data.Exists + '">' +
                                            '<button class="btn btn-success" ng-click="add(' + data.Id + ')">' +
                                                '<i class="glyphicon glyphicon-plus"></i>&nbsp;Tilføj' +
                                            '</button>&nbsp;' +
                                        '</p>';
                            }
                        }

                        $scope.add = function(productId){
                            if($scope.genericAtcId && !$scope.informationId) {
                                $scope.loadingProducts = true;
                                genericatcexceptedproductinformationservice
                                    .add($scope.genericAtcId, productId)
                                    .then(function () {
                                        $scope.dtInstanceProduct.rerender();
                                    });
                            }
                            if(!$scope.genericAtcId && $scope.informationId) {
                                $scope.loadingProducts = true;
                                informationproductservice
                                    .add($scope.informationId, productId)
                                    .then(function () {
                                        $scope.dtInstanceProduct.rerender();
                                    });
                            }
                        }

                        $scope.remove = function(productId){
                            if($scope.genericAtcId && !$scope.informationId) {
                                $scope.loadingProducts = true;
                                genericatcexceptedproductinformationservice
                                    .remove($scope.genericAtcId, productId)
                                    .then(function () {
                                        $scope.dtInstanceProduct.rerender();
                                    });
                            }
                            if(!$scope.genericAtcId && $scope.informationId) {
                                $scope.loadingProducts = true;
                                informationproductservice
                                    .remove($scope.informationId, productId)
                                    .then(function () {
                                        $scope.dtInstanceProduct.rerender();
                                    });
                            }
                        }

                        $scope.dtOptionsProduct =
                            DTOptionsBuilder.newOptions()
                                .withBootstrap()
                                .withFnServerData(function (sSource, aoData, fnCallback, oSettings) {
                                    if($scope.loadData) {
                                        var req = {
                                            draw: aoData[0].value,
                                            columns: aoData[1].value,
                                            order: aoData[2].value,
                                            start: aoData[3].value,
                                            length: aoData[4].value,
                                            search: aoData[5].value,
                                            page: $("#datatableProducts").DataTable().page.info().page + 1,
                                            filter: {
                                                informationId: $scope.informationId,
                                                genericAtcId: $scope.genericAtcId,
                                                markedExisting: $scope.markedExisting
                                            }
                                        };
                                        $scope.loadingProducts = true;
                                        productinformationservice
                                            .getPaged(req)
                                            .then(function (resp) {
                                                fnCallback(resp);
                                                $scope.loadingProducts = false;
                                            });
                                    }
                                })
                                .withDataProp('data')
                                .withLanguage({
                                    "sEmptyTable": "Ingen tilgængelige data (prøv igen eller en anden søgning)",
                                    "sInfo": "Viser _START_ til _END_ af _TOTAL_ rækker",
                                    "sInfoEmpty": "Viser 0 til 0 af 0 rækker",
                                    "sInfoFiltered": "(filtreret ud af _MAX_ rækker ialt)",
                                    "sInfoPostFix": "",
                                    "sInfoThousands": ",",
                                    "sLengthMenu": "Vis _MENU_ rækker",
                                    "sLoadingRecords": "Henter data...",
                                    "sProcessing": "<div class=\"loading\" id=\"loadingProducts\" ng-if=\"loadingProducts\"></div>",
                                    "sSearch": "Søg",
                                    "sSearchPlaceholder": "Søg her...",
                                    "sZeroRecords": "Ingen rækker matchede filter",
                                    "oPaginate": {
                                        "sFirst": "Første",
                                        "sLast": "Sidste",
                                        "sNext": "Næste",
                                        "sPrevious": "Forrige"
                                    }
                                })
                                .withOption('processing', true)
                                .withOption('serverSide', true)
                                .withPaginationType('simple_numbers')
                                .withOption('createdRow', createdRow)
                                .withDisplayLength(5)
                                .withOption('lengthMenu', [5,10, 30, 80, 200, 500]);

                        function createdRow(row, data, dataIndex) {
                            // Recompiling so we can bind Angular directive to the DT
                            $compile(angular.element(row).contents())($scope);
                        }

                        $scope.$watch('refreshProducts', function(){
                           if ($scope.refreshProducts && $scope.refreshProducts === true){
                               $scope.dtInstanceProduct.rerender();
                               $scope.refreshProducts = false;
                           }
                        });
                    }]
            };
        });
})(window.angular);

