(function (angular) {
    'use strict';
    angular.module('site.controllers')
        .controller('webOrderListController', ['$scope', '$timeout', '$location','$q','$http','$filter','DTDefaultOptions','DTOptionsBuilder', 'DTColumnBuilder', function($scope, $timeout, $location, $q, $http, $filter, DTDefaultOptions,DTOptionsBuilder, DTColumnBuilder) {

            $.fn.dataTable.ext.errMode = 'none';

            $scope.filterDateFrom = '';
            $scope.filterDateTo = '';
            $scope.dateFilterError = '';

            $scope.filterShowReceipt = false;
            $scope.filterShowMedication = false;
            $scope.filterShowBrandedProduct = false;

            $scope.searchText = '';
            $scope.pendingText = '';

            $scope.page = 0;
            $scope.pagesize = 10;
            $scope.redraw = 1;
            $scope.start = 0;

            $("#loading").show();
            $scope.pendingSearch = true;

            var filter = {};

            $scope.init = function (data)
            {
                filter = angular.fromJson(data);

                $scope.filterDateFrom = new Date(filter.DateFrom.Year,filter.DateFrom.Month,filter.DateFrom.Day);
                $scope.filterDateTo = new Date(filter.DateTo.Year,filter.DateTo.Month,filter.DateTo.Day);

                $scope.filterShowReceipt = filter.ShowReceipt;
                $scope.filterShowMedication = filter.ShowMedication;
                $scope.filterShowBrandedProduct = filter.ShowBrandedProduct;

                $scope.setFilterFromPath();

                $scope.pendingSearch = false;
            }

            $scope.dtColumns = [
                DTColumnBuilder.newColumn("OrderId").renderWith(renderOrderId).notSortable(),
                DTColumnBuilder.newColumn("TimeLabel", "Tidspunkt").withOption('name', 'Time').notSortable(),
                DTColumnBuilder.newColumn("Customer","Kunde").withOption("name","Customer").notSortable(),
                DTColumnBuilder.newColumn("OrderTotal", "Ordretotal").withOption('name', 'OrderTotal').notSortable(),
                DTColumnBuilder.newColumn("StatusLabel", "Status").withOption('name', 'StatusLabel').notSortable(),
                DTColumnBuilder.newColumn("ExtraInformation").withTitle('Andet').renderWith(renderExtraInformation).notSortable()
            ];

            function renderOrderId(data, type, full, meta) {
                var result = '';
                if (data) {
                    result = result + data + '<br>';
                    if (full.IsSelveoOrder)
                        result = result + '<span class="badge" style="background-color: darkmagenta">Firtal</span><br />';

                    if (full.IsHauldOrder)
                        result = result + '&nbsp;<span class="badge" style="background-color: darkgreen">Haul</span><br />';
                }
                return result;
            }

            function renderExtraInformation(data, type, full, meta)
            {
                var isSub = data.IsSubscription ? "Abonnement":"";
                var isRefri = data.ContainsRefrigeratedProducts ? "Frostvarer":"";
                var isAdult = data.RequiresAdultAccepted ? "18+":"";
                var result = '';
                if (isSub.length)
                    result = '<img src="dist/images/Subscription-icon.png" title="'+isSub+'" alt="'+isSub+'" style="height: 30px;"/>';
                if(result.length)
                    result = result + '<br>';
                if (isRefri)
                    result = result + '<img src="dist/images/freeze-icon.png" title="'+isRefri+'" alt="'+isRefri+'" style="height: 30px;"/>';
                if(result.length)
                    result = result + '<br>';
                if (isAdult)
                    result = result + '<img src="dist/images/18-plus.png"  title="'+isAdult+'" alt="'+isAdult+'" style="height: 30px;"/>';
                return result;
            }

            var lastReq;
            $scope.dtOptions = DTOptionsBuilder.newOptions()
                .withBootstrap()
                .withOption('ajax', {
                    url: '/webordersapi/getweborders',
                    type:"POST",
                    data: function(data){
                        $scope.updateFilters(data);
                    },
                    beforeSend: function (jpXhr, settings){
                        if (lastReq)
                            lastReq.abort();
                        lastReq = jpXhr;
                    }
                })
                .withDataProp('data')
                .withOption('rowCallback', function (nRow, aData, iDisplayIndex, iDisplayIndexFull) {
                    $('td', nRow).unbind('click');
                    $('td', nRow).bind('click', function(e) {
                        $scope.$apply(function() {
                            if (e.ctrlKey) {
                                window.open('/ravendborders/details/' + aData.BasketId, '_blank');
                            }
                            else{
                                location.href = '/ravendborders/details/' + aData.BasketId;
                            }
                        });
                    });

                    if (aData){
                        if (aData.IsPrescriptionOrder) {
                            nRow.style.backgroundColor = '#F7ECB4';
                        }
                        else if (aData.IsEanOrder){
                            nRow.style.backgroundColor = '#FFC0CB';
                        }
                        else if (!aData.IsMedicationOrder){
                            nRow.style.backgroundColor = '#9fe29b';
                        }
                    }
                    return nRow;
                })
                .withOption('initComplete', function() {
                    $("#loading").hide();

                    $scope.dtInstance.DataTable.on('page.dt', function () {
                        $scope.$apply(function(){
                            $location.search('webPagesize', $("#test").DataTable().page.info().length);
                            $location.search('webPage', $("#test").DataTable().page.info().page);
                            $location.search('webStart',$("#test").DataTable().page.info().start);
                        });
                        $("#loading").show();
                    });

                    $scope.dtInstance.DataTable.on('draw.dt', function () {
                        $scope.$apply(function(){
                            $location.search('webPagesize', $("#test").DataTable().page.info().length);
                            $location.search('webPage', $("#test").DataTable().page.info().page);
                            $location.search('webStart', $("#test").DataTable().page.info().start);
                        });

                        $("#loading").hide();
                        if ($("#loadingang"))
                            $("#loadingang").hide();
                    });

                    $scope.dtInstance.DataTable.on('length.dt', function (e,settings,len) {
                        $scope.pagesize = len;
                        $scope.$apply(function(){
                            $location.search('webPagesize', $("#test").DataTable().page.info().length);
                            $location.search('webPage', $("#test").DataTable().page.info().page);
                            $location.search('webStart', $("#test").DataTable().page.info().start);
                        });
                        $("#loading").show();
                    });

                    $scope.dtInstance.DataTable.on('error.dt', function (e, settings, techNote, message) {
                        alert("Der er desværre sket en fejl. Kontakt en udvikler. Luk ikke denne popup. (Udvikler, se i console efter luk)");
                        console.log(e);
                        console.log(settings);
                        console.log(techNote);
                        console.log(message);

                        $("#loading").hide();
                        if ($("#loadingang"))
                            $("#loadingang").hide();
                    });

                    var dt = $("#test").DataTable();
                    var queryObjects = $location.search();

                    $scope.pagesize = queryObjects.webPagesize ? parseInt(queryObjects.webPagesize) : 10;
                    $scope.page = queryObjects.webPage ? parseInt(queryObjects.webPage) : 0;

                    dt.page.len($scope.pagesize);
                    dt.page($scope.page).draw('page');
                })
                .withLanguage({
                    "sEmptyTable": "Ingen tilgængelige data (prøv igen eller en anden søgning)",
                    "sInfo": "Viser _START_ til _END_ af _TOTAL_ rækker",
                    "sInfoEmpty": "Viser 0 til 0 af 0 rækker",
                    "sInfoFiltered": "(filtreret ud af _MAX_ rækker ialt)",
                    "sInfoPostFix": "",
                    "sInfoThousands": ",",
                    "sLengthMenu": "Vis _MENU_ rækker",
                    "sLoadingRecords": "Henter data...",
                    "sProcessing": "Loading...",
                    "sSearch": "Søg på ordrenummer / kundenavn / email / telefonnr. ('Enter' for at søge):",
                    "sSearchPlaceholder": "Søg her...",
                    "sZeroRecords": "Ingen rækker matchede filter",
                    "oPaginate": {
                        "sFirst": "Første",
                        "sLast": "Sidste",
                        "sNext": "Næste",
                        "sPrevious": "Forrige"
                    }
                })
                .withOption('bFilter',false)
                .withOption('processing', false)
                .withOption('serverSide', true)
                .withPaginationType('simple_numbers')
                .withDisplayLength(10)
                .withOption('lengthMenu', [ 10, 25, 50, 75, 100,200,500])


            $scope.dtInstance = {};

            $scope.updateFilters = function(data)
            {
                data.search = {};
                data.search.value = $scope.searchText;
                data.search.regex = '';

                $location.search('webSearch', encodeURIComponent($scope.searchText))

                data.filter = {};
                data.filter.DateFrom = $filter('date')(new Date($scope.filterDateFrom),'dd-MM-yyyy');
                $location.search('webDateFrom',new Date($scope.filterDateFrom).getTime());

                data.filter.DateTo = $filter('date')(new Date($scope.filterDateTo),'dd-MM-yyyy');
                $location.search('webDateTo', new Date($scope.filterDateTo).getTime());

                data.filter.ShowReceipt = $scope.filterShowReceipt;
                $location.search('showReceipt', data.filter.ShowReceipt ? 1:0);

                data.filter.ShowMedication = $scope.filterShowMedication;
                $location.search('showMedication', data.filter.ShowMedication ? 1:0);

                data.filter.ShowBrandedProduct = $scope.filterShowBrandedProduct;
                $location.search('showBrandedProduct', data.filter.ShowBrandedProduct ? 1:0);

                var queryObjects = $location.search();

                data.length = $scope.pagesize;
                data.start = queryObjects.webStart ? parseInt(queryObjects.webStart) : 0;
            }

            $scope.filterChanged = function()
            {
                $scope.dateFilterError = '';
                if (!$scope.filterDateFrom || !$scope.filterDateTo)
                    return;

                var dateFrom = Date.parse($scope.filterDateFrom);
                var dateTo = Date.parse($scope.filterDateTo);

                if (dateTo < dateFrom)
                {
                    $scope.dateFilterError = '\'Til\' må ikke være før \'Fra\'.';
                    return;
                }

                $scope.reloadTable();
            }

            $scope.filterDate = function(ev){
                if(ev.keyCode === 13)
                    $scope.filterChanged();
            }

            $scope.changeDateFilter = function (daysBack){
                var nowDate = new Date();

                var dateFrom = $scope.subtractDays(nowDate, daysBack);
                var dateNow = nowDate;

                $scope.filterDateFrom = dateFrom;
                $scope.filterDateTo = dateNow;

                $scope.filterChanged();
            }

            $scope.subtractDays = function (date, days) {
                var result = new Date(date);
                result.setDate(date.getDate() - days);
                return result;
            }

            $scope.customSearch = function(ev){
                if (ev.keyCode === 13)
                    $scope.reloadTable();
            }

            $scope.reloadTable = function(){
                $("#loading").show();
                $scope.dtInstance.rerender();
            }

            $scope.setFilterFromPath = function()
            {
                var queryObjects = $location.search();
                if (!queryObjects)
                    return;

                if (queryObjects.webDateFrom) {
                    var dateFrom = new Date(parseInt(queryObjects.webDateFrom));
                    $scope.filterDateFrom = new Date(dateFrom.getFullYear(),dateFrom.getMonth(),dateFrom.getDate());
                }

                if (queryObjects.webDateTo) {
                    var dateTo = new Date(parseInt(queryObjects.webDateTo));
                    $scope.filterDateTo = new Date(dateTo.getFullYear(),dateTo.getMonth(),dateTo.getDate());
                }

                if (queryObjects.webSearch)
                    $scope.searchText = decodeURIComponent(queryObjects.webSearch);

                if (queryObjects.showReceipt)
                    $scope.filterShowReceipt = parseInt(queryObjects.showReceipt)  === 1;

                if (queryObjects.showMedication)
                    $scope.filterShowMedication = parseInt(queryObjects.showMedication) === 1;

                if (queryObjects.showBrandedProduct)
                    $scope.filterShowBrandedProduct = parseInt(queryObjects.showBrandedProduct)  === 1;

                if (queryObjects.webPage)
                    $scope.page = parseInt(queryObjects.webPage);

                if (queryObjects.webPagesize)
                    $scope.pagesize = parseInt(queryObjects.webPagesize);

                if (queryObjects.start)
                    $scope.start = parseInt(queryObjects.start);
            }

            //https://l-lin.github.io/angular-datatables/archives/#!/bindAngularDirective

        }]);
})(window.angular);
