(function (angular) {
    'use strict';
    angular.module('site.services')
        .factory('selveoservice', [ '$http','$q', function ($http, $q) {

            return {
                getOrders : function (orderNumbers) {
                    var deferred = $q.defer();
                    return $http
                        .get('/api/selveo/order?orderNumbers=' + orderNumbers)
                        .then(function (response) {
                            deferred.resolve(response.data);
                            return deferred.promise;
                        }, function (response) {
                            deferred.reject(response);
                            return deferred.promise;
                        });
                },
                lockOrder : function (orderNumber) {
                    var deferred = $q.defer();
                    return $http
                        .put('/api/selveo/order/' + orderNumber + '/lock')
                        .then(function (response) {
                            deferred.resolve(response.data);
                            return deferred.promise;
                        }, function (response) {
                            deferred.reject(response);
                            return deferred.promise;
                        });
                },
                unlockOrder : function (orderNumber) {
                    var deferred = $q.defer();
                    return $http
                        .put('/api/selveo/order/' + orderNumber + '/unlock')
                        .then(function (response) {
                            deferred.resolve(response.data);
                            return deferred.promise;
                        }, function (response) {
                            deferred.reject(response);
                            return deferred.promise;
                        });
                },
            }
        }]);
})(window.angular);

