angular.module('site.controllers')
    .controller('subscriptionProfileController', ['$scope', '$http', '$timeout', '$location', function ($scope, $http, $timeout, $location) {

        $scope.init = function () {
            var profileJson = $('#profile-details-container').attr('data-profile');
            $scope.profile = angular.fromJson(profileJson.toString());

            console.log($scope.profile);
        };

        $scope.goToSubscription = function(subscription) {
            location.href = '/subscriptions/details/'+subscription.Id;
        }
        $scope.goBack = function(){
            return history.back();
        }
    }]);
