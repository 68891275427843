(function (angular) {
    'use strict';
    angular.module('site.directives')
        .directive('anatomictherapeuticchemicalsSelect', function () {
            return {
                restrict: 'E',
                transclude: true,
                scope: {
                    genericAtcId:"=",
                    refreshProducts:"="
                },
                templateUrl: '/Layout/js/html/anatomictherapeuticchemicals-select.html',
                controller: ['$scope', '$compile','$location', 'anatomictherapeuticchemicalinformationservice','genericatcanatomictherapeuticchemicalinformationservice', 'DTOptionsBuilder', 'DTColumnBuilder', 'DTColumnDefBuilder',
                    function ($scope, $compile, $location, anatomictherapeuticchemicalinformationservice,genericatcanatomictherapeuticchemicalinformationservice, DTOptionsBuilder, DTColumnBuilder, DTColumnDefBuilder) {

                        $.fn.dataTable.ext.errMode = 'none';

                        $scope.pending = false;

                        $scope.dtInstanceAnatomicalTherapeuticChemical = {};

                        $scope.dtColumnsAnatomicalTherapeuticChemical = [
                            DTColumnBuilder.newColumn(null).renderWith(renderOptions).notSortable(),
                            DTColumnBuilder.newColumn("Id", "ID").withOption('name', 'Id').notVisible(),
                            DTColumnBuilder.newColumn("Code", "ATC kode").withOption('name', 'Code'),
                            DTColumnBuilder.newColumn("IngredientText", "Beskrivelse").withOption('name', 'IngredientText')
                        ];

                        function renderOptions(data, type, full, meta)
                        {
                            if(data)
                            {
                                return '<p ng-if="' + data.Exists + '">' +
                                            '<button class="btn btn-danger" ng-click="remove(' + "'" + data.Code + "'"  + ')">' +
                                                '<i class="glyphicon glyphicon-remove"></i>&nbsp;Fjern' +
                                            '</button>&nbsp;' +
                                        '</p>'+
                                        '<p ng-if="!' + data.Exists + '">' +
                                            '<button class="btn btn-success" ng-click="add(' + "'" + data.Code + "'" +')">' +
                                                '<i class="glyphicon glyphicon-plus"></i>&nbsp;Tilføj' +
                                            '</button>&nbsp;' +
                                        '</p>';
                            }
                        }

                        $scope.add = function(code){
                            $scope.pending = true;
                            genericatcanatomictherapeuticchemicalinformationservice
                                .add($scope.genericAtcId,code)
                                .then(function(){
                                    $scope.dtInstanceAnatomicalTherapeuticChemical.rerender();
                                    $scope.refreshProducts = true;
                                });
                        }

                        $scope.remove = function(code){
                            $scope.pending = true;
                            genericatcanatomictherapeuticchemicalinformationservice
                                .remove($scope.genericAtcId,code)
                                .then(function (){
                                    $scope.dtInstanceAnatomicalTherapeuticChemical.rerender();
                                    $scope.refreshProducts = true;
                                });
                        }

                        $scope.dtOptionsAnatomicalTherapeuticChemical =
                            DTOptionsBuilder.newOptions()
                                .withBootstrap()
                                .withFnServerData(function (sSource, aoData, fnCallback, oSettings) {
                                    var req = {
                                        draw: aoData[0].value,
                                        columns: aoData[1].value,
                                        order: aoData[2].value,
                                        start: aoData[3].value,
                                        length: aoData[4].value,
                                        search: aoData[5].value,
                                        page: $("#datatableAnatomicalTherapeuticChemicals").DataTable().page.info().page + 1,
                                        filter: {
                                            genericAtcId: $scope.genericAtcId,
                                            markedExisting: true
                                        }
                                    };
                                    $scope.pending = true;
                                    anatomictherapeuticchemicalinformationservice
                                        .getPaged(req)
                                        .then(function (resp) {
                                            fnCallback(resp);
                                            $scope.pending = false;
                                        });
                                })
                                .withDataProp('data')
                                .withLanguage({
                                    "sEmptyTable": "Ingen tilgængelige data (prøv igen eller en anden søgning)",
                                    "sInfo": "Viser _START_ til _END_ af _TOTAL_ rækker",
                                    "sInfoEmpty": "Viser 0 til 0 af 0 rækker",
                                    "sInfoFiltered": "(filtreret ud af _MAX_ rækker ialt)",
                                    "sInfoPostFix": "",
                                    "sInfoThousands": ",",
                                    "sLengthMenu": "Vis _MENU_ rækker",
                                    "sLoadingRecords": "Henter data...",
                                    "sProcessing": "<div class=\"loading\" id=\"loading\" ng-if=\"pending\"></div>",
                                    "sSearch": "Søg",
                                    "sSearchPlaceholder": "Søg her...",
                                    "sZeroRecords": "Ingen rækker matchede filter",
                                    "oPaginate": {
                                        "sFirst": "Første",
                                        "sLast": "Sidste",
                                        "sNext": "Næste",
                                        "sPrevious": "Forrige"
                                    }
                                })
                                .withOption('processing', true)
                                .withOption('serverSide', true)
                                .withPaginationType('simple_numbers')
                                .withOption('createdRow', createdRow)
                                .withDisplayLength(5)
                                .withOption('lengthMenu', [5, 10, 30, 80, 200, 500]);


                        function createdRow(row, data, dataIndex) {
                            // Recompiling so we can bind Angular directive to the DT
                            $compile(angular.element(row).contents())($scope);
                        }

                    }]
            };
        });
})(window.angular);

