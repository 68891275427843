(function (angular) {
    'use strict';
    angular.module('site.services')
        .factory('genericatcexceptedproductinformationservice', [ '$http','$q', function ($http, $q) {
            return {
                add : function (genericAtcId, productId) {
                    var data = {
                      id: productId
                    };
                    var api = '/api/genericatcexceptedproductinformation/'+genericAtcId;
                    var deferred = $q.defer();
                    return $http
                        .post(api, JSON.stringify(data))
                        .then(function (response) {
                            deferred.resolve(response.data);
                            return deferred.promise;
                        }, function (response) {
                            deferred.reject(response);
                            return deferred.promise;
                        });
                },
                remove : function (genericAtcId, productId) {
                    var api = '/api/genericatcexceptedproductinformation/'+genericAtcId+'/'+productId;
                    var deferred = $q.defer();
                    return $http
                        .delete(api)
                        .then(function (response) {
                            deferred.resolve(response.data);
                            return deferred.promise;
                        }, function (response) {
                            deferred.reject(response);
                            return deferred.promise;
                        });
                }
            }
        }]);
})(window.angular);

