(function (angular) {
    'use strict';
    angular.module('site.services')
        .factory('genericatcinformationservice', [ '$http','$q', function ($http, $q) {
            return {
                getPaged : function (data) {
                    var api = 'api/genericatcinformation/searchpaged';
                    var deferred = $q.defer();
                    return $http
                        .post(api, JSON.stringify(data))
                        .then(function (response) {
                            deferred.resolve(response.data);
                            return deferred.promise;
                        }, function (response) {
                            deferred.reject(response);
                            return deferred.promise;
                        });
                },
                create : function(informationId){
                    var api = 'api/genericatcinformation/create';
                    var deferred = $q.defer();
                    return $http
                        .post(api, JSON.stringify({
                            information:{
                                id: informationId
                            }
                        }))
                        .then(function (response) {
                            deferred.resolve(response.data);
                            return deferred.promise;
                        }, function (response) {
                            deferred.reject(response);
                            return deferred.promise;
                        });
                },
                get : function (id) {
                    var api = id ? '/api/genericatcinformation/'+ id : '/api/genericatcinformation';
                    var deferred = $q.defer();
                    return $http
                        .get(api)
                        .then(function (response) {
                            deferred.resolve(response.data);
                            return deferred.promise;
                        }, function (response) {
                            deferred.reject(response);
                            return deferred.promise;
                        });
                }
            }
        }]);
})(window.angular);

