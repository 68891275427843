angular.module('site.controllers')
    .controller('userDetailsController', ['$scope', '$http', '$window','informationlogservice', function($scope, $http, $window,informationlogservice) {

        // variables for this scope
        $scope.data = {
            user : null, // will contain user profile data
            formNames: {
                address: 'profileAddressForm'
            },
            loading : {
                loadingUser : true, // when loading user
                pendingChanges: false // when performing action
            },
            regex : {
                email : /^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))$/i,
                phone : /(?=^.{3,20}$)^\+?[0-9]+(\([0-9]+\))?[0-9-]*[0-9]$/
            },
            countries: [],
            showValidationMessages: false
        };

        $scope.informationLogs = [];
        $scope.informationLogSearchTerm = '';

        // initialization
        $scope.initialize = function(){
            // profile
            var userProfileJson = $('#user-details-container').attr('data-profile');
            $scope.data.user = angular.fromJson(userProfileJson.toString()) || [];

            console.log($scope.data.user);

            // user id
            $scope.data.user.id = $('#user-details-container').attr('data-user-id');

            // countries
            var countriesJson = $('#user-details-container').attr('data-countries');
            $scope.data.countries = angular.fromJson(countriesJson.toString()) || [];

            $scope.searchInformationLogs();
        };

        // validation
        $scope.isValidAddressLine = function(addressLine){
            if(!addressLine){
                return true;
            }else{
                return /\d+/g.test(addressLine);
            }
        };

        // actions
        $scope.requestDeletion = function(){
            var r = confirm("Er du sikker at slette brugeren?");
            if (r == true) {
                $scope.data.loading.pendingChanges = true;
                $http.post('/usersapi/requestDelete?userId='+$scope.data.user.id, null)
                .then(function (data) {
                    ShowSuccessToast('Brugeren bliver snart slettet');
                }, function (response) {
                    $scope.data.loading.pendingChanges = false;
                    ShowErrorToast("Fejl for at slette brugeren");
                });
            }
        }

        $scope.unsubscribeFromNewsletter = function(){
            if ($scope.data.user.type == 1){
                // confirmation needed for club members
                var r = confirm("Hvis du framelder bruger fra vores nyhedsbrev, melder du bruger også ud af kundeklubben");
                if (r == false) {
                    return;
                }

            }

            $scope.data.loading.pendingChanges = true;
            $http.post('/usersapi/unsubscribe?userId='+$scope.data.user.id, null)
            .then(function (data) {
                ShowSuccessToast('Brugeren er frameld fra vores nyhedsbrev');
            }, function (response) {
                $scope.data.loading.pendingChanges = false;
                ShowErrorToast("Fejl for at frameld brugeren fra vores nyhedsbrev");
            });
        };

        $scope.subscribeToNewsletter = function(){
            if (!confirm("Bekræft at du vil tilmelde nyhedsbrev ?"))
                return;

            $scope.data.loading.pendingChanges = true;
            $http.post('/usersapi/subscribe?userId='+$scope.data.user.id, null)
            .then(function (data) {
                ShowSuccessToast('Brugeren er tilmeld til vores nyhedsbrev');
            },function(response) {
                $scope.data.loading.pendingChanges = false;
                ShowErrorToast("Fejl for at frameld brugeren fra vores nyhedsbrev");
            });
        };

        $scope.saveAddress = function(){

            $scope.data.showValidationMessages = true;
            if ($scope[$scope.data.formNames.address].$invalid || $scope.data.loading.pendingChanges){
                console.log("Address form is invalid");
                return false;
            }

            // check if form is pristine? skip updating API then
            if ($scope[$scope.data.formNames.address].$pristine){
                $scope.data.showValidationMessages = false;
                console.log("Address form is pristine");
                ShowSuccessToast('Bruger er opdateret');
                return false;
            }

            // ensure address field is valid
            if (!$scope.isValidAddressLine($scope.data.user.address.addressLine)){
                return false;
            }

            updateUserAddress($scope.data.user);
        };

        function updateUserAddress(user) {
            $scope.data.loading.pendingChanges = true;
            $http.post('/usersapi/editAddress?userId='+user.id, {
                firstName: user.firstName,
                lastName: user.lastName,
                phone: user.phone,
                email: user.email,
                address : {
                    addressLine: user.address.addressLine,
                    countryIsoCode: user.address.countryIsoCode,
                    postalCode: user.address.postalCode,
                    city : user.address.city,
                    eanNumber: user.address.eanNumber,
                    addressType: user.address.addressType,
                    companyName: user.address.companyName
                }
            })
            .then(function (data) {
                $scope.data.loading.pendingChanges = false;
                ShowSuccessToast('Bruger er opdateret');
            },function (response) {
                $scope.data.loading.pendingChanges = false;
                ShowErrorToast("Couldn't update user");
            });
        };

        $scope.downgradeToStandard = function() {
            if (confirm("Bekræft at du vil nedgradere til standard ?")){

                $scope.data.loading.pendingChanges = true;
                $http
                    .post('/usersapi/downgradeToStandard?userId=' + $scope.data.user.id)
                    .then(function (data) {
                            $scope.data.loading.pendingChanges = false;
                            location.reload();
                        },
                        function (resp) {
                            $scope.data.loading.pendingChanges = false;
                            ShowErrorToast("Couldn't downgrade user");
                        });
            }
        }

        // toasts (to be moved to separate service)
        function ShowSuccessToast(text){
            ShowToast(text, 5000, "#5cb85c");
        };

        function ShowErrorToast(text){
            ShowToast(text, 5000, "#d9534f");
        };

        function ShowToast(text, duration, color){
            // https://github.com/apvarun/toastify-js/blob/master/README.md
            Toastify({
                text: text,
                duration: duration,
                close: true,
                gravity: "bottom", // `top` or `bottom`
                positionLeft: false, // `true` or `false`
                backgroundColor: color,
                }).showToast();
        };

        $scope.searchInformationLogs = function(){
            $scope.pendingLogs = true;
            informationlogservice
                .search($scope.data.user.email, $scope.informationLogSearchTerm)
                .then(function(resp){
                    $scope.informationLogs = resp;
                    $scope.pendingLogs = false;
                });
        }
    }
]);
