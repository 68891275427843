(function (angular) {
    'use strict';
    angular.module('site.controllers')
        .controller('productsController', ['$scope', 'productinformationservice', function ($scope, productinformationservice) {

            $scope.pendingProductsSync = false;

            $scope.productsSync = function (e){
                e.preventDefault();
                $scope.pendingProductsSync = true;
                productinformationservice
                    .productsSync()
                    .then(function(){
                        $scope.pendingProductsSync = false;
                    });
            }

        }]);
})(window.angular);
