angular.module('site.directives')
	.directive('profileFieldError', ['$timeout', function($timeout) {
		var errorMapper = function(error, text){
			return {
				error: error,
				text: text
			};
		};
		return {
			scope: true,
            template: '<p class="error-message" ng-class="{active:hasError}">'+
                '<span ng-animate="anim-fade" ng-repeat="error in errors track by error.text" ng-show="error.error">{{error.text}}</span>'+
                '</p>',
			replace: true,
			link: function(scope, elem, attr) {
				scope.$watch(attr.profileFieldError, function(err){
                    if(_.isObject(err)){
						scope.errors = _.map(err, errorMapper);
						scope.hasError = _.find(err, function(error, text){
							return error;
						});
					}
				}, true);
			}
		};
	}]);


