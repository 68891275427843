(function (angular) {
    'use strict';
    angular.module('site.services')
        .factory('productinformationservice', [ '$http','$q', function ($http, $q) {
            return {
                getPaged : function (data) {
                    var api = '/api/productinformation/searchpaged';
                    var deferred = $q.defer();
                    return $http
                        .post(api, JSON.stringify(data))
                        .then(function (response) {
                            deferred.resolve(response.data);
                            return deferred.promise;
                        }, function (response) {
                            deferred.reject(response);
                            return deferred.promise;
                        });
                },
                productsSync : function () {
                    var api = '/api/productinformation/ProductsSync';
                    var deferred = $q.defer();
                    return $http
                        .post(api)
                        .then(function (response) {
                            deferred.resolve(response.data);
                            return deferred.promise;
                        }, function (response) {
                            deferred.reject(response);
                            return deferred.promise;
                        });
                },
            }
        }]);
})(window.angular);

