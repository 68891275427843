(function (angular) {
    'use strict';
    angular.module('site.controllers')
        .controller('dashboardController', ['$scope', '$http','$interval', '$filter','dashboardservice', function ($scope, $http, $interval, $filter, dashboardservice) {

            $scope.pendingTodayOrdersStatistics = false;
            $scope.pendingExpeditionOrdersStatistics = false;
            $scope.pendingPackagingOrdersStatistics = false;
            $scope.pendingLogisticsOrdersStatistics = false;
            $scope.pendinglastUpdateTime = false;

            $scope.isSearchIndexStale = false;

            $scope.lastUpdateTime = new Date();

            $scope.model = {
                todayOrdersStatistics:{},
                expeditionOrdersStatistics: {},
                packagingOrdersStatistics:{},
                logisticsOrdersStatistics:{}
            }

            $scope.init = function () {
                $scope.getTodayOrdersStatistics();
                $scope.getExpeditionOrdersStatistics();
                $scope.getPackagingOrdersStatistics();
                $scope.getLogisticsOrdersStatistics();
                $scope.getIsSearchIndexStale();
                $scope.lastUpdateTime = new Date();
            };

            $interval(function(){
                $scope.pendinglastUpdateTime = true;
                $scope.getTodayOrdersStatistics();
                $scope.getExpeditionOrdersStatistics();
                $scope.getPackagingOrdersStatistics();
                $scope.getLogisticsOrdersStatistics();
                $scope.getIsSearchIndexStale();
                $scope.lastUpdateTime = new Date();
                $scope.pendinglastUpdateTime = false;
            }, 60000);

            $scope.getTodayOrdersStatistics = function(){
                $scope.pendingTodayOrdersStatistics = true;
                dashboardservice
                    .getTodayOrdersStatistics()
                    .then(function(data){
                        console.log("getTodayOrdersStatistics", data);
                        $scope.model.todayOrdersStatistics = data;
                        $scope.pendingTodayOrdersStatistics = false;
                    });
            };

            $scope.getExpeditionOrdersStatistics = function(){
                $scope.pendingExpeditionOrdersStatistics = true;
                dashboardservice
                    .getExpeditionOrdersStatistics()
                    .then(function(data){
                        console.log("getExpeditionOrdersStatistics", data);
                        $scope.model.expeditionOrdersStatistics = data;
                        $scope.pendingExpeditionOrdersStatistics = false;
                    });
            };

            $scope.getPackagingOrdersStatistics = function(){
                $scope.pendingPackagingOrdersStatistics = true;
                dashboardservice
                    .getPackagingOrdersStatistics()
                    .then(function(data){
                        console.log("getPackagingOrdersStatistics", data);
                        $scope.model.packagingOrdersStatistics = data;
                        $scope.pendingPackagingOrdersStatistics = false;
                    });
            };

            $scope.getLogisticsOrdersStatistics = function(){
                $scope.pendingLogisticsOrdersStatistics = true;
                dashboardservice
                    .getLogisticsOrdersStatistics()
                    .then(function(data){
                        console.log("getLogisticsOrdersStatistics", data);
                        $scope.model.logisticsOrdersStatistics = data;
                        $scope.pendingLogisticsOrdersStatistics = false;
                    });
            };

            $scope.getIsSearchIndexStale = function(){
                $scope.isSearchIndexStale = true;
                dashboardservice
                    .isSearchIndexStale()
                    .then(function(data){
                        console.log("isSearchIndexStale", data);
                        $scope.isSearchIndexStale = data;
                        $scope.pendingSearchIndexStale = false;
                    });
            };
        }]);
})(window.angular);
