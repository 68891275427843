angular.module('site.controllers')
    .controller('userListController', ['$scope', '$http', '$timeout', '$location','$q', function($scope, $http, $timeout, $location,$q) {

        var searchPromise;

        $scope.init = function() {
            var lastQuery = $location.path('/search');

            if (lastQuery && lastQuery.search && lastQuery.search().q) {
                $scope.showInProgress = true;
                $scope
                    .search(lastQuery.search().q)
                    .then(function(resp) {
                        $scope.users = resp.data;
                        $scope.pendingSearch = false;
                    },function (response) {
                        alert("Kan ikke hente data.");
                        console.log("Kan ikke hente data.", response);
                        $scope.pendingSearch = false;
                    });
            }
        };

        $scope.inputChanged = function() {
            if ($scope.searchQuery && $scope.searchQuery.length < 3 && $scope.searchQuery.length > 0)
                return;

            $scope.pendingSearch = true;

            $scope.usedMailChecked = false;
            $scope.usedMail = false;

            if (searchPromise)
                $timeout.cancel(searchPromise);

            searchPromise = $timeout(function() {
                $scope
                    .search($scope.searchQuery)
                    .then(function(resp) {
                        $scope.users = resp.data;
                        $scope.pendingSearch = false;
                    },function (response) {
                        alert("Kan ikke hente data.");
                        console.log("Kan ikke hente data.", response);
                        $scope.pendingSearch = false;
                    });
            }, 400);
        };

        $scope.checkUsedEmail = function(event, email){
            event.preventDefault();
            $scope.doCheckUsedEmail(email)
                .then(function(resp){
                    $scope.usedMailChecked = true;
                    $scope.usedMail = resp.data === true;
                },function (response) {
                    alert("Fejl.");
                    console.log("Fejl.", response);
                    $scope.usedMailChecked = true;
                });
        };

        $scope.doCheckUsedEmail = function(email){
            var deferred = $q.defer();
            $http.post('/usersapi/checkusedemail?email=' + email)
                .then(function(resp){
                    deferred.resolve(resp);
                },function (response) {
                    deferred.reject(response);
                    alert("Fejl.");
                    console.log("Fejl.", response);
                    return deferred.promise;
                });
            return deferred.promise;
        };

        $scope.deleteUsedEmail = function(event, email){
            event.preventDefault();
            $scope
                .doDeleteUsedEmail(email)
                .then(function(resp){
                    if (resp.data.hasError !== undefined && resp.data.hasError){
                        $scope.deleteUsedMailErrorMessages = resp.data.messages;
                    }
                    else{
                        $scope.usedMailChecked = false;
                    }
                },function (response) {
                    alert("Fejl i slet email.");
                    console.log("Fejl i slet email.", response);
                    $scope.usedMailChecked = true;
                });
        };

        $scope.doDeleteUsedEmail = function(email){
            var deferred = $q.defer();
            $http
                .post('/usersapi/deleteusedemail?email=' + email)
                .then(function(data){
                    deferred.resolve(data);
                },function (response) {
                    deferred.reject(response);
                    alert("Fejl i slet email.");
                    console.log("Fejl i slet email.", response);
                    return deferred.promise;
                });
            return deferred.promise;
        };

        $scope.search = function(query) {
            maintainState();
            var deferred = $q.defer();
            $http.
                get('/usersapi/search?q=' + query).
                then(function (data) {
                    deferred.resolve(data);
                },function (response) {
                    deferred.reject(response);
                    alert("Kan ikke hente data.");
                    console.log("Kan ikke hente data.", response);
                    return deferred.promise;
                });
            return deferred.promise;
        }

        function maintainState() {
            $location.path('/search').search({
                q: $scope.searchQuery
            });
        }

        $scope.goToProfileDetails = function(user){
            location.href = '/users/details/'+user.Id;
        }
    }
    ]);
