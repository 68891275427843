angular.module('site.controllers')
    .controller('adminUserListController', ['$scope', '$http', '$timeout', '$location', function($scope, $http, $timeout, $location) {

        var searchPromise;

        $scope.init = function () {

            var lastQuery = $location.path('/admin/search');

            if (lastQuery && lastQuery.search && lastQuery.search().q) {
                $scope.showInProgress = true;
                restoreState(lastQuery.search());
            } else {
                $scope.showInProgress = false;
                $scope.searchQuery = '';
                loadAdminUsers('');
                $('#q').focus();
            }

            $scope.filterRole = 'All';
        };

        $scope.submitSearch = function(e) {
            e.preventDefault();
        };

        $scope.inputChanged = function () {

            $scope.pendingSearch = true;
            if (searchPromise)
                $timeout.cancel(searchPromise);

            searchPromise = $timeout(function () {
                $scope.completeSearch = false;
                loadAdminUsers($scope.searchQuery, $scope.filterRole);
            }, 400);
        };

        function loadAdminUsers(query, role) {

            maintainState();

            var url = '/adminuserapi/getallusers?query=' + query;
            if (role) {
                url += '&role=' + role;
            }

            $http.get(url)
                .then(function(resp) {
                    $scope.adminusers = resp.data;
                    $scope.pendingSearch = false;
                },function (response) {
                    alert("Fejl.");
                    console.log("Fejl.", response);
                });
        }

        function maintainState() {
            $location.path('/search').search({
                q: $scope.searchQuery,
                inProgress: $scope.showInProgress.toString(),
            });
        }

        function restoreState(query) {
            $scope.searchQuery = query.q;
            $scope.showInProgress = query.inProgress == 'true';
            $scope.completeSearch = query.all == 'true';
            loadAdminUsers(query.q);
       }

       $scope.goToDetails = function(adminUser){
           location.href = '/adminuser/details/'+adminUser.Id;
       }

    }]);
