angular.module('site.controllers')
    .controller('subscriptionDetailsController', ['$scope', '$http','$q', function ($scope, $http,$q) {
        var subscriptionDeleteEdit;
        $scope.init = function (id) {
            var subscriptionJson = $('#subscription-details-container').attr('data-profile');
            $scope.subscription = angular.fromJson(subscriptionJson.toString());
        };

        $scope.addNewProduct = function (e) {
            e.preventDefault();

            $scope.
                doAddProduct($scope.subscription.Id, $scope.newProductSku, $scope.newProductQuantity).
                then(function (resp) {
                    $scope.subscription = resp.data;
                },function (response) {
                    console.log(response);
                });
        }

        $scope.removeProduct = function (e, sku) {
            e.preventDefault();
            $scope.
                doRemoveProduct($scope.subscription.Id, sku).
                then(function (resp) {
                    $scope.subscription = resp.data;
                },function (response) {
                    console.log(response);
                });
        }

        $scope.updateQuantity = function (e, sku, quantity) {
            e.preventDefault();

            $scope.
                doUpdateQuantity($scope.subscription.Id, sku, quantity).
                then(function (resp) {
                    $scope.subscription = resp.data;
                },function (response) {
                    console.log(response);
                });
        }

        $scope.editInterval = function (e) {
            e.preventDefault();

            $scope
                .getDeliveryIntervalOptions()
                .then(function(resp)
                {
                    $scope.deliveryIntervalEdit = {
                        deliveryIntervalOptions: resp.data,
                        selectedEditInterval: $scope.subscription.DaysBetweenDeliveries
                    }
                    $scope.deliveryIntervalOptions = resp.data;
                },function (response) {
                    console.log(response);
                });
        }

        $scope.editActive = function(e){
            e.preventDefault();

            $scope.subscriptionActiveEdit = {
                active: $scope.subscription.Active == 'Aktiv' ? "active" : "inactive"
            };
        }

        $scope.editDeleted = function(e){
            e.preventDefault();

            $scope.subscriptionDeletedEdit = {
                deleted: $scope.subscription.IsDeleted ? "deleted" : "notDeleted"
            };
        }

        $scope.setDeliveryIntervalOption = function () {
            $scope.
                doDeliveryIntervalOption($scope.subscription.Id, $scope.deliveryIntervalEdit.selectedEditInterval).
                then(function(resp){
                    $scope.subscription = resp.data;
                    $scope.deliveryIntervalEdit = null;
                },function (response) {
                    console.log(response);
                });
        }

        $scope.setSubscriptionActive = function(e){
            var active = $scope.subscriptionActiveEdit.active == 'active';
            $scope.
                doSubscriptionActive($scope.subscription.Id, active).
                then(function(resp){
                    $scope.subscription = resp.data;
                    $scope.subscriptionActiveEdit = null;
                },function (response) {
                    console.log(response);
                });
        }

        $scope.setSubscriptionDeleted = function(e){
            var deleted = $scope.subscriptionDeletedEdit.deleted == 'deleted';
            $scope.
                doSubscriptionDeleted($scope.subscription.Id, deleted).
                then(function(resp){
                    $scope.subscription = resp.data;
                    $scope.subscriptionDeletedEdit = null;
                },function (response) {
                    console.log(response);
                });
        }

        $scope.getDeliveryIntervalOptions = function() {
            var url = '/subscriptionsapi/deliveryIntervalOptions';
            var deferred = $q.defer();
            $http.get(url)
                .then(function (data) {
                    deferred.resolve(data);
                },function (response) {
                    deferred.reject(response);
                    alert("Fejl i leverings interval");
                    console.log("Fejl i leverings interval", response);
                    return deferred.promise;
                });
            return deferred.promise;
        }

        $scope.doDeliveryIntervalOption = function(subscriptionId, interval) {
            var url = '/subscriptionsapi/setInterval';
            var deferred = $q.defer();
            $http
                .post(url, { subscriptionId: subscriptionId, interval: interval })
                .then(function (resp){
                    deferred.resolve(resp);
                },function (response) {
                    deferred.reject(response);
                    alert("Fejl i sæt interval");
                    console.log("Fejl i sæt interval", response);
                    return deferred.promise;
                });
            return deferred.promise;
        }

        $scope.doSubscriptionActive = function (subscriptionId, active){
            var url = '/subscriptionsapi/setActive';
            var deferred = $q.defer();
            $http
                .post(url, { subscriptionId: subscriptionId, active: active})
                .then(function (resp){
                    deferred.resolve(resp);
                },function (response) {
                    deferred.reject(response);
                    alert("Fejl i sæt aktiv");
                    console.log("Fejl i sæt aktiv", response);
                    return deferred.promise;
                });
            return deferred.promise;
        }

        $scope.doAddProduct = function(subscriptionId, sku, quantity) {
            var url = '/subscriptionsapi/add';
            var deferred = $q.defer();
            $http
                .post(url, { subscriptionId: subscriptionId, sku: sku, quantity: quantity })
                .then(function (resp){
                    deferred.resolve(resp);
                },function (response) {
                    deferred.reject(response);
                    alert("Fejl i tilføjelse af product");
                    console.log("Fejl i tilføjelse af product", response);
                    return deferred.promise;
                });
            return deferred.promise;
        }

        $scope.doRemoveProduct = function(subscriptionId, sku, cont) {
            var url = '/subscriptionsapi/remove';
            var deferred = $q.defer();
            $http
                .post(url, { subscriptionId: subscriptionId, sku: sku })
                .then(function (resp){
                    deferred.resolve(resp);
                },function (response) {
                    deferred.reject(response);
                    alert("Fejl i fjernelse af product");
                    console.log("Fejl i fjernelse af product", response);
                    return deferred.promise;
                });
            return deferred.promise;
        }

        $scope.doUpdateQuantity = function(subscriptionId, sku, quantity) {
            var url = '/subscriptionsapi/updatequantity';
            var deferred = $q.defer();
            $http
                .post(url, { subscriptionId: subscriptionId, sku: sku, quantity: quantity })
                .then(function (resp){
                    deferred.resolve(resp);
                },function (response) {
                    deferred.reject(response);
                    alert("Fejl i opdatering af antal");
                    console.log("Fejl i opdatering af antal", response);
                    return deferred.promise;
                });
            return deferred.promise;
        }

        $scope.doSubscriptionDeleted = function(subscriptionId, deleted){
            var url = '/subscriptionsapi/deleteSubscription';
            var deferred = $q.defer();
            $http
                .post(url, { subscriptionId: subscriptionId, deleted: deleted })
                .then(function (resp){
                    deferred.resolve(resp);
                },function (response) {
                    deferred.reject(response);
                    alert("Fejl i slet abonnement");
                    console.log("Fejl i slet abonnement", response);
                    return deferred.promise;
                });
            return deferred.promise;
        }

        $scope.goToOrderDetails = function(delivery){
            location.href = '/orders/details/'+delivery.OrderId;
        }

        $scope.goBack = function(){
            return history.back();
        }

    }]);
