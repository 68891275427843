(function (angular) {
    'use strict';
    angular.module('site.services')
        .factory('wholesellerservice', [ '$http','$q', function ($http, $q) {

            return {
                getOrderLines : function (orderNumber) {
                    var deferred = $q.defer();
                    return $http
                        .get('/api/wholeseller/order/' + orderNumber + '/orderlines')
                        .then(function (response) {
                            deferred.resolve(response.data);
                            return deferred.promise;
                        }, function (response) {
                            deferred.reject(response);
                            return deferred.promise;
                        });
                },
            }
        }]);
})(window.angular);

