(function (angular) {
    'use strict';
    angular.module('site.services')
        .factory('dashboardservice', [ '$http','$q', function ($http, $q) {
            return {
                getTodayOrdersStatistics : function () {
                    var deferred = $q.defer();
                    return $http
                        .get('/dashboardapi/gettodayordersstatistics')
                        .then(function (response) {
                            deferred.resolve(response.data);
                            return deferred.promise;
                        }, function (response) {
                            deferred.reject(response);
                            return deferred.promise;
                        });
                },
                getExpeditionOrdersStatistics : function () {
                    var deferred = $q.defer();
                    return $http
                        .get('/dashboardapi/getexpeditionordersstatistics')
                        .then(function (response) {
                            deferred.resolve(response.data);
                            return deferred.promise;
                        }, function (response) {
                            deferred.reject(response);
                            return deferred.promise;
                        });
                },
                getPackagingOrdersStatistics : function () {
                    var deferred = $q.defer();
                    return $http
                        .get('/dashboardapi/getpackagingordersstatistics')
                        .then(function (response) {
                            deferred.resolve(response.data);
                            return deferred.promise;
                        }, function (response) {
                            deferred.reject(response);
                            return deferred.promise;
                        });
                },
                getLogisticsOrdersStatistics : function () {
                    var deferred = $q.defer();
                    return $http
                        .get('/dashboardapi/getlogisticsordersstatistics')
                        .then(function (response) {
                            deferred.resolve(response.data);
                            return deferred.promise;
                        }, function (response) {
                            deferred.reject(response);
                            return deferred.promise;
                        });
                },
                isSearchIndexStale: function () {
                    var deferred = $q.defer();
                    return $http
                        .get('/dashboardapi/IsSearchIndexStale')
                        .then(function (response) {
                            deferred.resolve(response.data);
                            return deferred.promise;
                        }, function (response) {
                            deferred.reject(response);
                            return deferred.promise;
                        });
                },
            }
        }]);
})(window.angular);

