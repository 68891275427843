(function (angular) {
    'use strict';
    angular.module('site.directives')
        .directive('postalCodesSelect', function () {
            return {
                restrict: 'E',
                transclude: true,
                scope: {
                    twoLetterIsoCode:"=",
                    carrierId:"=",
                    postalCodes:"=",
                },
                templateUrl: '/Layout/js/html/postalcodes-select.html',
                controller: ['$scope', '$compile', '$location','mutualservice', function ($scope, $compile, $location, mutualservice) {

                        $scope.pending = false;

                        $scope.init = function()
                        {
                            $scope.getPostalCodes();
                        }

                        $scope.getPostalCodes = function()
                        {
                            $scope.pending = true;
                            mutualservice
                                .getPostalCodes($scope.twoLetterIsoCode)
                                .then(function(rsp){
                                    $scope.postalCodes = rsp;
                                    $scope.pending = false;
                                })
                        }
                    }]
            };
        });
})(window.angular);

