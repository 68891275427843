(function (angular) {
    'use strict';
    angular.module('site.services')
        .factory('webshipperservice', [ '$http','$q', function ($http, $q) {

            return {
                getCarrier : function (carrierId) {
                    var deferred = $q.defer();
                    return $http
                        .get('/api/webshipper/carrier/'+carrierId)
                        .then(function (response) {
                            deferred.resolve(response.data);
                            return deferred.promise;
                        }, function (response) {
                            deferred.reject(response);
                            return deferred.promise;
                        });
                },
                getAvailableCarriers: function(){
                    var deferred = $q.defer();
                    return $http
                        .get('/api/webshipper/carrier')
                        .then(function (response) {
                            deferred.resolve(response.data);
                            return deferred.promise;
                        }, function (response) {
                            deferred.reject(response);
                            return deferred.promise;
                        });
                },
            }
        }]);
})(window.angular);
