(function (angular) {
    'use strict';
    angular.module('site.controllers')
        .controller('indexInformationController', ['$scope', '$filter', '$compile','$sce','$location','$timeout','DTOptionsBuilder', 'DTColumnBuilder','DTColumnDefBuilder','informationservice','productinformationservice','genericatcinformationservice',
            function ($scope, $filter, $compile,$sce, $location,$timeout, DTOptionsBuilder, DTColumnBuilder, DTColumnDefBuilder, informationservice, productinformationservice,genericatcinformationservice) {

            $.fn.dataTable.ext.errMode = 'none';

            $scope.pendingInformation = false;
            $scope.pendingGenericAtc = false;
            $scope.pendingProducts = false;
            $scope.pendingSaveSuccess = false;
            $scope.pendingSaveInformation = false;

            $scope.loadData = false;
            $scope.refreshProducts = false;



            $scope.allInformation = [];

            $scope.selectedInformation = null;
            $scope.genericAtcInformations = [];

            $scope.init = function () {
                $scope.getAllInformation();
            };

            $scope.getAllInformation = function (){
                $scope.pendingInformation = true;
                $scope.allInformation = [];

                informationservice
                    .get()
                    .then(function (resp){
                        $scope.pendingInformation = false;
                        $scope.allInformation = resp;
                    });
            }

            $scope.dtInstanceGeneric = {};

            $scope.dtColumnsGeneric = [
                DTColumnBuilder.newColumn("Id", "ID").withOption('name', 'Id').notVisible(),
                DTColumnBuilder.newColumn(null).withTitle("ATC-Koder").renderWith(renderAtcCodes).notSortable(),
                DTColumnBuilder.newColumn(null).withTitle("Undladte varenumre").renderWith(renderExceptedSkus).notSortable(),
                DTColumnBuilder.newColumn(null).withTitle().renderWith(renderEditGeneric).notSortable()
            ];

            function renderExceptedSkus(data, type, full, meta)
            {
                if(data && data.ExceptedProducts)
                {
                    return '<p><button class="btn btn-info" ng-click="showExceptedProducts(' + data.Id + ')">' +
                        '<i class="glyphicon glyphicon-info-sign"></i>' +
                        '</button>&nbsp;'+temporaryText(data.ExceptedProducts,3)+'</p>';
                }
            }

            function renderAtcCodes(data, type, full, meta)
            {
                if(data && data.AnatomicTherapeuticChemicals)
                {
                    return '<p><button class="btn btn-info" ng-click="showAtcCodes(' + data.Id + ')">' +
                        '<i class="glyphicon glyphicon-info-sign"></i>' +
                        '</button>&nbsp;'+temporaryText(data.AnatomicTherapeuticChemicals,3)+'</p>';
                }
            }

            function temporaryText(list, count)
            {
                var text = '';
                var counter = 1;
                var i = 0;
                while (counter <= count && i < list.length)
                {
                    var t = list[i].Name ? list[i].Name : list[i].Code;
                    text += t+',';
                    i++;
                    counter++;
                }

                if (text.indexOf(',', text.length-1))
                    text = text.substr(0,text.length-1);

                if(list.length > count)
                    text += '...';

                if (!text)
                    text = 'Intet';

                return text;
            }

            function renderEditGeneric(data, type, full, meta)
            {
                if(data) {
                    return '<a href="/information/EditGenericAtc/'+ data.Id +'" class="btn btn-warning">' +
                        '<i class="glyphicon glyphicon-edit"></i>' +
                        '</a>&nbsp;';
                }
            }

            $scope.dtOptionsGeneric =
                DTOptionsBuilder.newOptions()
                    .withBootstrap()
                    .withFnServerData(function (sSource, aoData, fnCallback, oSettings){
                        if ($scope.loadData) {
                            var req = {
                                draw: aoData[0].value,
                                columns: aoData[1].value,
                                order: aoData[2].value,
                                start: aoData[3].value,
                                length: aoData[4].value,
                                search: aoData[5].value,
                                page: $("#datatableGenericatc").DataTable().page.info().page + 1,
                                filter: {
                                    informationId: $scope.selectedInformation ? $scope.selectedInformation.Id : null
                                }
                            };
                            $scope.pendingGenericAtc = true;
                            genericatcinformationservice
                                .getPaged(req)
                                .then(function (resp) {
                                    fnCallback(resp);
                                    $scope.genericAtcInformations = resp.data;
                                    $scope.pendingGenericAtc = false;
                                });
                        }
                    })
                    .withDataProp('data')
                    .withLanguage({
                        "sEmptyTable": "Ingen tilgængelige data (prøv igen eller en anden søgning)",
                        "sInfo": "Viser _START_ til _END_ af _TOTAL_ rækker",
                        "sInfoEmpty": "Viser 0 til 0 af 0 rækker",
                        "sInfoFiltered": "(filtreret ud af _MAX_ rækker ialt)",
                        "sInfoPostFix": "",
                        "sInfoThousands": ",",
                        "sLengthMenu": "Vis _MENU_ rækker",
                        "sLoadingRecords": "Henter data...",
                        "sProcessing": "<div class=\"loading\" id=\"loadingGenericAtc\" ng-if=\"pendingGenericAtc\"></div>",
                        "sSearch": "Søg",
                        "sSearchPlaceholder": "Søg her...",
                        "sZeroRecords": "Ingen rækker matchede filter",
                        "oPaginate": {
                            "sFirst": "Første",
                            "sLast": "Sidste",
                            "sNext": "Næste",
                            "sPrevious": "Forrige"
                        }
                    })
                    .withOption('bFilter',false)
                    .withOption('processing', true)
                    .withOption('serverSide', true)
                    .withPaginationType('simple_numbers')
                    .withOption('createdRow', createdRow)
                    .withDisplayLength(10)
                    .withOption('lengthMenu', [ 10, 20, 30, 40, 50]);

            function createdRow(row, data, dataIndex) {
                $compile(angular.element(row).contents())($scope);
            }

            $scope.genericAtcInformation = {};
            $scope.showAtcCodes = function (id){
                $scope.genericAtcInformation = $scope.genericAtcInformations.filter(function(g){
                    return g.Id === id;
                })[0];
                $('#atcCodesModel').modal('show');
            }

            $scope.showExceptedProducts = function (id){
                $scope.genericAtcInformation = $scope.genericAtcInformations.filter(function(g){
                    return g.Id === id;
                })[0];
                $('#exceptedProductsModel').modal('show');
            }

            $scope.showInformationLanguages = function (){
                $('#informationLanguageTextsModel').modal('show');
            }

            $scope.htmlBind = function(htmlText)
            {
                return $sce.trustAsHtml(htmlText);
            }

            $scope.reloadDatatables = function(){
                $scope.loadData = true;
                $scope.dtInstanceGeneric.rerender();
                $scope.refreshProducts = true;
            }

            $scope.createGenericAtc = function(){
                $scope.pendingGenericAtc = true;
                genericatcinformationservice
                    .create($scope.selectedInformation.Id)
                    .then(function(rsp){
                        $scope.dtInstanceGeneric.rerender();
                        $scope.pendingGenericAtc = false;
                    });
            }

            $scope.filterInput = function($event){
                if(isNaN(String.fromCharCode($event.keyCode))){
                    $event.preventDefault();
                }
            }

            $scope.save = function(){
                $scope.pendingSaveInformation = true;
                informationservice
                    .update($scope.selectedInformation.Id, $scope.selectedInformation)
                    .then(function(){
                        $scope.pendingSaveInformation = false;
                        $scope.pendingSaveSuccess = true;
                        $timeout(function(){$scope.pendingSaveSuccess = false; },5000)

                    });
            }

        }]);
})(window.angular);
