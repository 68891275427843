(function (angular) {
    'use strict';
    angular.module('site.services')
        .factory('informationlogservice', [ '$http','$q', function ($http, $q) {
            return {
                search : function (emailAddress, searchTerm) {
                    var api = '/api/informationlog/search?emailAddress='+emailAddress+'&searchTerm='+searchTerm
                    var deferred = $q.defer();
                    return $http
                        .get(api)
                        .then(function (response) {
                            deferred.resolve(response.data);
                            return deferred.promise;
                        }, function (response) {
                            deferred.reject(response);
                            return deferred.promise;
                        });
                }
            }
        }]);
})(window.angular);

