(function (angular) {
    'use strict';
    angular.module('site.services')
        .factory('mutualservice', [ '$http','$q', function ($http, $q) {

            return {
                getPostalCodes : function (twoLetterIsoCode) {
                    var deferred = $q.defer();
                    return $http
                        .get('/api/mutual/Globalization/'+twoLetterIsoCode+'/PostalCodes')
                        .then(function (response) {
                            deferred.resolve(response.data);
                            return deferred.promise;
                        }, function (response) {
                            deferred.reject(response);
                            return deferred.promise;
                        });
                },
            }
        }]);
})(window.angular);

