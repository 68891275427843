(function (angular) {
    'use strict';
    angular.module('site.services')
        .factory('orderservice', [ '$http','$q', function ($http, $q) {

            return {
                getOrderDetails : function (orderNumber) {
                    var deferred = $q.defer();
                    return $http
                        .get('/ordersapi/getOrderDetails?orderNumber=' + orderNumber)
                        .then(function (response) {
                            deferred.resolve(response.data);
                            return deferred.promise;
                        }, function (response) {
                            deferred.reject(response);
                            return deferred.promise;
                        });
                },
                getOrderLinesDetails : function (orderNumber) {
                    var deferred = $q.defer();
                    return $http
                        .get('/ordersapi/getOrderLinesDetails?orderNumber=' + orderNumber)
                        .then(function (response) {
                            deferred.resolve(response.data);
                            return deferred.promise;
                        }, function (response) {
                            deferred.reject(response);
                            return deferred.promise;
                        });
                },
                getShippingDetails : function (orderNumber) {
                    var deferred = $q.defer();
                    return $http
                        .get('/ordersapi/getShippingDetails?orderNumber=' + orderNumber)
                        .then(function (response) {
                            deferred.resolve(response.data);
                            return deferred.promise;
                        }, function (response) {
                            deferred.reject(response);
                            return deferred.promise;
                        });
                },
                getPaymentDetails : function (orderNumber) {
                    var deferred = $q.defer();
                    return $http
                        .get('/ordersapi/getPaymentDetails?orderNumber=' + orderNumber)
                        .then(function (response) {
                            deferred.resolve(response.data);
                            return deferred.promise;
                        }, function (response) {
                            deferred.reject(response);
                            return deferred.promise;
                        });
                },
                capture : function (orderNumber) {
                    var deferred = $q.defer();
                    return $http
                        .post('/ordersapi/manuelcapture?orderNumber=' + orderNumber)
                        .then(function (response) {
                            deferred.resolve(response.data);
                            return deferred.promise;
                        }, function (response) {
                            deferred.reject(response);
                            return deferred.promise;
                        });
                },
                reSendConfirmation : function(data){
                    var deferred = $q.defer();
                    return $http
                        .post('/ordersapi/resendorderconfirmation', JSON.stringify(data))
                        .then(function (response) {
                            deferred.resolve(response.data);
                            return deferred.promise;
                        }, function (response) {
                            deferred.reject(response);
                            return deferred.promise;
                        });
                },
                changeOrderStatus: function(data){
                    var deferred = $q.defer();
                    return $http
                        .post('/ordersapi/changeStatus', JSON.stringify(data))
                        .then(function (response) {
                            deferred.resolve(response.data);
                            return deferred.promise;
                        }, function (response) {
                            deferred.reject(response);
                            return deferred.promise;
                        });
                },
                cancelPayment: function(data){
                    var deferred = $q.defer();
                    return $http
                        .post('/ordersapi/cancelPayment', JSON.stringify(data))
                        .then(function (response) {
                            deferred.resolve(response.data);
                            return deferred.promise;
                        }, function (response) {
                            deferred.reject(response);
                            return deferred.promise;
                        });
                },
                rePrintLabel: function(data){
                    var deferred = $q.defer();
                    return $http
                        .post('/ordersapi/printDeliveryNote', JSON.stringify(data))
                        .then(function (response) {
                            deferred.resolve(response.data);
                            return deferred.promise;
                        }, function (response) {
                            deferred.reject(response);
                            return deferred.promise;
                        });
                },
                ship: function(data){
                    var deferred = $q.defer();
                    return $http
                        .post('/ordersapi/ship', JSON.stringify(data))
                        .then(function (response) {
                            deferred.resolve(response.data);
                            return deferred.promise;
                        }, function (response) {
                            deferred.reject(response);
                            return deferred.promise;
                        });
                },
                printLabel: function(data){
                    var deferred = $q.defer();
                    return $http
                        .post('/ordersapi/printLabel', JSON.stringify(data))
                        .then(function (response) {
                            deferred.resolve(response.data);
                            return deferred.promise;
                        }, function (response) {
                            deferred.reject(response);
                            return deferred.promise;
                        });
                },
            }
        }]);
})(window.angular);

