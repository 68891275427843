(function (angular) {
    'use strict';
    angular.module('site.controllers')
        .controller('genericAtcInformationController', ['$scope', '$filter', 'informationservice','productinformationservice', 'genericatcinformationservice',
            function ($scope, $filter, informationservice, productinformationservice, genericatcinformationservice) {

                $scope.pendingInformation = false;
                $scope.preSelectedInformation = null;
                $scope.preSelectedGenericAtc = null;
                $scope.preSelectedGenericAtcId = null;

                $scope.refreshProducts = false;

                $scope.init = function (model){
                    var m = angular.fromJson(model);

                    if(m.InformationId) {
                        $scope.getInformation(m.InformationId);
                    }

                    if(m.GenericAtcId) {
                        $scope.getGenericAtc(m.GenericAtcId);
                        $scope.preSelectedGenericAtcId = m.GenericAtcId;
                    }
                }

                $scope.getInformation = function (id){
                    $scope.pendingInformation = true;

                    informationservice
                        .get(id)
                        .then(function (resp){
                            $scope.pendingInformation = false;
                            $scope.preSelectedInformation = resp;
                        });
                }

                $scope.getGenericAtc = function (id){
                    $scope.pendingInformation = true;

                    genericatcinformationservice
                        .get(id)
                        .then(function (resp){
                            $scope.preSelectedGenericAtc = resp;
                            $scope.getInformation(resp.Information.Id);
                        });
                }

                $scope.goBack = function (){
                    return history.back();
                }
            }]);
})(window.angular);
