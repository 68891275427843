(function (angular) {
    'use strict';
angular.module('site.controllers')
    .controller('orderDetailsController', ['$scope', '$window', 'orderservice','selveoservice', 'changelogservice','wholesellerservice','DTOptionsBuilder', 'DTColumnBuilder',
        function($scope, $window, orderservice, selveoservice, changelogservice, wholesellerservice, DTOptionsBuilder, DTColumnBuilder) {

        $scope.pendingOrderOverview = false;
        $scope.pendingOrderLines = false;
        $scope.pendingShipping = false;
        $scope.pendingWholeSellerOrderLines = false;
        $scope.pendingPayment = false;
        $scope.pendingLockUnlock = false;

        $scope.locked = undefined;
        $scope.unlocked = undefined;

        $scope.orderModel = {};

        $scope.paymentCancelledError = '';


        $scope.editEmailForm = {
            emailAddress: '',
            sendNewConfirmationMail: false
        };

        $scope.editOrderStatusForm = {
            orderStatus:''
        };

        $scope.model = {
            order: {
                IsEanOrder: false
            },
            orderLines: {},
            shipping: {},
            wholeSellerOrderLines : {},
            payment: {}
        };

        $scope.init = function (orderDetailsModel){
            $scope.orderModel = angular.fromJson(orderDetailsModel);

            $scope.getOrderInformation();
            $scope.getOrderLines();
            $scope.getShipping();
            $scope.getWholeSellerOrderLines();
            $scope.getPaymentInformation();
        };

        $scope.getOrderInformation = function(){
            $scope.pendingOrderOverview = true;
            orderservice
                .getOrderDetails($scope.orderModel.OrderNumber)
                .then(function(data){
                    console.log("getOrderInformation", data);
                    $scope.model.order = data;
                    if (data) {
                        $scope.editEmailForm.emailAddress = data.Order.EmailAddress;
                        $scope.editOrderStatusForm.orderStatus = data.Order.StatusText;
                        if (data.Order.SelveoInformation) {
                            $scope.locked = data.Order.SelveoInformation.IsLocked === true;
                            $scope.unlocked = data.Order.SelveoInformation.IsLocked === false;
                        }
                    }

                    $scope.pendingOrderOverview = false;
                });
        };

        $scope.getOrderLines = function(){
            $scope.pendingOrderLines = true;
            orderservice
                .getOrderLinesDetails($scope.orderModel.OrderNumber)
                .then(function(data){
                    console.log("getOrderLines", data);
                    $scope.model.orderLines = data;

                    $scope.pendingOrderLines = false;
                });
        };

        $scope.getShipping = function(){
            $scope.pendingShipping = true;
            orderservice
                .getShippingDetails($scope.orderModel.OrderNumber)
                .then(function(data){
                    console.log("getShipping", data);
                    $scope.model.shipping = data;

                    $scope.pendingShipping = false;
                });
        };

        $scope.getWholeSellerOrderLines = function(){
            $scope.pendingWholeSellerOrderLines = true;
            wholesellerservice
                .getOrderLines($scope.orderModel.OrderNumber)
                .then(function(data){
                    $scope.model.wholeSellerOrderLines = data;

                    $scope.pendingWholeSellerOrderLines = false;
                });
        };

        $scope.getPaymentInformation = function(){
            $scope.pendingPayment = true;
            orderservice
                .getPaymentDetails($scope.orderModel.OrderNumber)
                .then(function(data){
                    console.log("getPaymentInformation", data);
                    $scope.model.payment = data;

                    $scope.pendingPayment = false;
                });
        };

        $scope.capture = function(){
            $scope.pendingPayment = true;
            orderservice
                .capture($scope.orderModel.OrderNumber)
                .then(function(){
                    $scope.getPaymentInformation();
                });
        };

        $scope.saveNewEmailAddress = function(){
            console.log("saveNewEmailAddress", $scope.editEmailForm);
            orderservice
                .reSendConfirmation({
                    orderNumber: $scope.model.order.Order.OrderNumber,
                    emailAddress: $scope.editEmailForm.emailAddress,
                    reSendConfirmationMail: $scope.editEmailForm.sendNewConfirmationMail
                })
                .then(function(resp){
                    $scope.getOrderInformation();

                    $('#changeEmailModal').modal('hide')
                });
        }

        $scope.setNewStatus = function(newStatus){
            $scope.editOrderStatusForm.orderStatus = newStatus;
        }

        $scope.changeOrderStatus = function(){
            console.log("changeOrderStatus", $scope.editOrderStatusForm);
            orderservice
                .changeOrderStatus({
                    orderNumber: $scope.model.order.Order.OrderNumber,
                    newOrderStatus: $scope.editOrderStatusForm.orderStatus,
                })
                .then(function(resp){
                    $scope.getOrderInformation();

                    $('#changeOrderStatusModal').modal('hide')
                });
        }

        $scope.cancelPayment = function(){
            $scope.paymentCancelledError = '';
            orderservice
                .cancelPayment({
                    orderNumber: $scope.model.order.Order.OrderNumber
                })
                .then(function(resp){
                    if(resp && resp.IsSuccess) {
                        $scope.getOrderInformation();
                        $scope.getPaymentInformation();

                        $('#cancelPaymentModal').modal('hide')
                    }
                    if (resp && !resp.IsSuccess){
                        $scope.paymentCancelledError = resp.Message;
                    }
                });
        }

        $scope.rePrintLabel = function(){
            console.log("rePrintLabel");
            orderservice
                .rePrintLabel({
                    orderNumber: $scope.model.order.Order.OrderNumber
                })
                .then(function(resp){
                    $('#rePrintLabel').modal('hide')
                });
        }

        $scope.shipOrPrint = function(){
            console.log("shipOrPrint");
            if ($scope.model.order.Order.StatusText === 'InProgress') {
                orderservice
                    .ship({
                        orderNumber: $scope.model.order.Order.OrderNumber
                    })
                    .then(function (resp) {
                        $('#shipOrPrintModal').modal('hide')
                    });
            }
            else{
                orderservice
                    .printLabel({
                        orderNumber: $scope.model.order.Order.OrderNumber
                    })
                    .then(function (resp) {
                        $('#shipOrPrintModal').modal('hide')
                    });
            }
        }

        $scope.lockOrUnlockOrder = function(){
            if (!$scope.locked && $scope.unlocked) {
                $scope.pendingLockUnlock = true;
                selveoservice
                    .lockOrder($scope.model.order.Order.OrderNumber)
                    .then(function (rsp) {
                        $scope.getOrderInformation();
                        $scope.pendingLockUnlock = false;
                    });
            }
            else if ($scope.locked && !$scope.unlocked)
            {
                $scope.pendingLockUnlock = true;
                selveoservice
                    .unlockOrder($scope.model.order.Order.OrderNumber)
                    .then(function (rsp) {
                        $scope.getOrderInformation();
                        $scope.pendingLockUnlock = false;
                    });
             }
        }

        $.fn.dataTable.ext.errMode = 'none';

        $scope.dtColumnsChangeLogs = [
            DTColumnBuilder.newColumn("LoggedDateTime", "Tidspunkt").withOption('name', 'LogTime'),
            DTColumnBuilder.newColumn("LogMessage", "Tekst").withOption('name', 'LogMessage').notSortable(),
            DTColumnBuilder.newColumn("CreatedBy", "Noteret af").withOption('name', 'CreatedBy'),
        ];

        $scope.dtOptionsChangeLogs =
            DTOptionsBuilder.newOptions()
                .withBootstrap()
                .withFnServerData(function (sSource, aoData, fnCallback, oSettings) {
                    var req = {
                        draw: aoData[0].value,
                        columns: aoData[1].value,
                        order: aoData[2].value,
                        start: aoData[3].value,
                        length: aoData[4].value,
                        search: aoData[5].value,
                        page: $("#datatableChangeLogs").DataTable().page.info().page + 1
                    };
                    $scope.pending = true;
                    changelogservice
                        .searchPaged($scope.orderModel.OrderNumber, req)
                        .then(function (resp) {
                            fnCallback(resp);
                            $scope.pending = false;
                        });
                })
                .withDataProp('data')
                .withLanguage({
                    "sEmptyTable": "Ingen tilgængelige data (prøv igen eller en anden søgning)",
                    "sInfo": "Viser _START_ til _END_ af _TOTAL_ rækker",
                    "sInfoEmpty": "Viser 0 til 0 af 0 rækker",
                    "sInfoFiltered": "(filtreret ud af _MAX_ rækker ialt)",
                    "sInfoPostFix": "",
                    "sInfoThousands": ",",
                    "sLengthMenu": "Vis _MENU_ rækker",
                    "sLoadingRecords": "Henter data...",
                    "sProcessing": "<div class=\"loading\" id=\"loading\" ng-if=\"pending\"></div>",
                    "sSearch": "Søg",
                    "sSearchPlaceholder": "Søg her...",
                    "sZeroRecords": "Ingen rækker matchede filter",
                    "oPaginate": {
                        "sFirst": "Første",
                        "sLast": "Sidste",
                        "sNext": "Næste",
                        "sPrevious": "Forrige"
                    }
                })
                .withOption('processing', true)
                .withOption('serverSide', true)
                .withOption('columnDefs',[
                    { width: 100, targets: 0 },
                    { width: 100, targets: 2 }
                ])
                .withOption("order",[[0, 'desc']])
                .withPaginationType('simple_numbers')
                .withDisplayLength(5)
                .withOption('lengthMenu', [5, 10, 30, 80, 200, 500]);


        $scope.dtInstanceChangeLogs = {};


        $scope.goBack = function (){
            return history.back();
        }

}]);
})(window.angular);
