(function (angular) {
    'use strict';
    angular.module('site.controllers')
        .controller('informationController', ['$scope', '$filter', 'informationservice',
            function ($scope, $filter, informationservice) {

                $scope.pendingInformation = false;
                $scope.preSelectedInformation = null;
                $scope.preSelectedInformationId = null;

                $scope.addedProduct = null;
                $scope.removedProduct = null;

                $scope.init = function (model){
                    var m = angular.fromJson(model);
                    $scope.preSelectedInformationId = m.InformationId;
                    $scope.getInformation(m.InformationId);
                }

                $scope.getInformation = function (id){
                    $scope.pendingInformation = true;

                    informationservice
                        .get(id)
                        .then(function (resp){
                            $scope.pendingInformation = false;
                            $scope.preSelectedInformation = resp;
                            $scope.preSelectedInformationId = resp.Id;
                        });
                }

                $scope.goBack = function (){
                    return history.back();
                }
            }]);
})(window.angular);
