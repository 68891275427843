(function (angular) {
    'use strict';
angular.module('site.controllers')
    .controller('orderListController', ['$scope', '$timeout', '$location','$q','$http','$filter','DTDefaultOptions','DTOptionsBuilder', 'DTColumnBuilder', function($scope, $timeout, $location, $q, $http, $filter, DTDefaultOptions,DTOptionsBuilder, DTColumnBuilder) {

        $.fn.dataTable.ext.errMode = 'none';

        $scope.filterDateFrom = '';
        $scope.filterDateTo = '';
        $scope.dateFilterError = '';

        $scope.filterStatusList = [];

        $scope.searchText = '';
        $scope.pendingText = '';

        $scope.page = 0;
        $scope.pagesize = 10;
        $scope.start = 0;

        $("#loading").show();
        $scope.pendingSearch = true;

        var filter = {};

        $scope.init = function (data)
        {
            filter = angular.fromJson(data);

            $scope.filterDateFrom = new Date(filter.DateFrom.Year,filter.DateFrom.Month,filter.DateFrom.Day);
            $scope.filterDateTo = new Date(filter.DateTo.Year,filter.DateTo.Month,filter.DateTo.Day);

            $scope.filterStatusList = filter.StatusList;

            $scope.setFilterFromPath();

            $scope.pendingSearch = false;

        }

        $scope.dtColumns = [
            DTColumnBuilder.newColumn("OrderNumber").renderWith(renderOrderNumber).notSortable(),
            DTColumnBuilder.newColumn("OrderDate", "Tidspunkt").withOption('name', 'OrderDate').notSortable(),
            DTColumnBuilder.newColumn("Customer").withTitle("Kunde").withClass('cust').renderWith(renderCustomer).notSortable(),
            DTColumnBuilder.newColumn("OrderTotal", "Ordretotal").withOption('name', 'OrderTotal').notSortable(),
            DTColumnBuilder.newColumn("Status", "Status").renderWith(renderStatus).notSortable(),
            DTColumnBuilder.newColumn("ShippingMethod", "Levering").withOption('name', 'ShippingMethod').notSortable(),
            DTColumnBuilder.newColumn("IsPrescriptionOrder").notVisible(),
            DTColumnBuilder.newColumn("ProfileType", "Brugertype").withOption('name', 'ProfileType').notSortable(),
            DTColumnBuilder.newColumn("ExtraInformation").withTitle('Andet').renderWith(renderExtraInformation).notSortable()
        ];

        function renderOrderNumber(data, type, full, meta) {
            var result = '';
            if (data) {
                result = result + data + '<br>';
                if (full.IsSelveoOrder)
                {
                    result = result + '<span class="badge" style="background-color: darkmagenta">Firtal</span>';

                    if (full.IsHaulOrder)
                        result = result + '&nbsp;<span class="badge" style = "background-color: darkgreen" >Haul</span > '

                    if(full.SelveoInformation && full.SelveoInformation.IsLocked)
                        result = result + '&nbsp;<span class="glyphicon glyphicon-lock" title="Ordre låst" style="color: orangered"></span>';
                }
                result = result + '<br />';
            }
            return result;
        }

        function renderCustomer(data, type, full, meta) {
            var result = '';
            if (data) {
                result = result + data.Name + '<br>';
                if (data.Email)
                    result = result + '<a target="_blank" href="https://'+full.SendMailUrl+'" title="'+data.Email+'"><span class="glyphicon glyphicon-envelope" aria-hidden="true"></span>&nbsp;Send mail</a><br />';
            }
            return result;
        }

        function renderStatus(data, type, full, meta) {
            var result = '';
            if (data) {
                result = result + data + '<br>';
                if (full.SelveoInformation)
                    result = result + '<span class="badge" style="background-color: darkmagenta">'+full.SelveoInformation.OrderStatus+'</span><br />';
            }
            return result;
        }

        function renderExtraInformation(data, type, full, meta)
        {
            var isSub = data.IsSubscription ? "Abonnement":"";
            var isRefri = data.ContainsRefrigeratedProducts ? "Frostvarer":"";
            var isAdult = data.RequiresAdultAccepted ? "18+":"";
            var result = '';
            if (isSub.length)
                result = '<img src="dist/images/Subscription-icon.png" title="'+isSub+'" alt="'+isSub+'" style="height: 30px;"/>';
            if(result.length)
                result = result + '<br>';
            if (isRefri)
                result = result + '<img src="dist/images/freeze-icon.png" title="'+isRefri+'" alt="'+isRefri+'" style="height: 30px;"/>';
            if(result.length)
                result = result + '<br>';
            if (isAdult)
                result = result + '<img src="dist/images/18-plus.png"  title="'+isAdult+'" alt="'+isAdult+'" style="height: 30px;"/>';
            return result;
        }
        var lastReq;
        $scope.dtOptions = DTOptionsBuilder.newOptions()
            .withBootstrap()
            .withOption('ajax', {
                url: '/ordersapi/getorders',
                type:"POST",
                data: function(data){
                    $scope.updateFilters(data);
                },
                beforeSend: function (jpXhr, settings){
                    if (lastReq)
                        lastReq.abort();
                    lastReq = jpXhr;
                }
            })
            .withDataProp('data')
            .withOption('rowCallback', function (nRow, aData, iDisplayIndex, iDisplayIndexFull) {
                $('td', nRow).unbind('click');
                $('td', nRow).bind('click', function(e) {
                    $scope.$apply(function () {
                        if (e.ctrlKey) {
                            window.open('/orders/details/' + aData.OrderNumber, '_blank');
                        }
                        else{
                            location.href = '/orders/details/' + aData.OrderNumber;
                        }
                    });
                });

                $('td.cust', nRow).unbind('click');

                if (aData){
                    if (aData.IsPrescriptionOrder) {
                        nRow.style.backgroundColor = '#F7ECB4';
                    }
                    else if (aData.IsEanOrder){
                        nRow.style.backgroundColor = '#FFC0CB';
                    }
                    else if (!aData.IsMedicationOrder){
                        nRow.style.backgroundColor = '#9fe29b';
                    }
                }
                return nRow;
            })
            .withOption('initComplete', function() {
                $("#loading").hide();

                $scope.dtInstance.DataTable.on('page.dt', function (e, settings) {
                    $scope.$apply(function(){
                        $location.search('pagesize', $("#test").DataTable().page.info().length);
                        $location.search('page', $("#test").DataTable().page.info().page);
                        $location.search('start', $("#test").DataTable().page.info().start);
                    });

                    $("#loading").show();
                });

                $scope.dtInstance.DataTable.on('draw.dt', function () {
                    $scope.$apply(function(){
                        $location.search('pagesize', $("#test").DataTable().page.info().length);
                        $location.search('page', $("#test").DataTable().page.info().page);
                        $location.search('start', $("#test").DataTable().page.info().start);
                    });

                    $("#loading").hide();
                    if ($("#loadingang"))
                        $("#loadingang").hide();
                });

                $scope.dtInstance.DataTable.on('length.dt', function (e,settings,len) {
                    $scope.pagesize = len;
                    $scope.$apply(function(){
                        $location.search('pagesize', $("#test").DataTable().page.info().length);
                        $location.search('page', $("#test").DataTable().page.info().page);
                        $location.search('start', $("#test").DataTable().page.info().start);
                    });
                    $("#loading").show();
                });

                $scope.dtInstance.DataTable.on('error.dt', function (e, settings, techNote, message) {
                    alert("Der er desværre sket en fejl. Kontakt en udvikler. Luk ikke denne popup. (Udvikler, se i console efter luk)");
                    console.log(e);
                    console.log(settings);
                    console.log(techNote);
                    console.log(message);

                    $("#loading").hide();
                    if ($("#loadingang"))
                        $("#loadingang").hide();
                });


                var dt = $("#test").DataTable();
                var queryObjects = $location.search();

                $scope.pagesize = queryObjects.pagesize ? parseInt(queryObjects.pagesize) : 10;
                $scope.page = queryObjects.page ? parseInt(queryObjects.page) : 0;

                dt.page.len($scope.pagesize);
                dt.page($scope.page).draw('page');


            })
            .withLanguage({
                "sEmptyTable": "Ingen tilgængelige data (prøv igen eller en anden søgning)",
                "sInfo": "Viser _START_ til _END_ af _TOTAL_ rækker",
                "sInfoEmpty": "Viser 0 til 0 af 0 rækker",
                "sInfoFiltered": "(filtreret ud af _MAX_ rækker ialt)",
                "sInfoPostFix": "",
                "sInfoThousands": ",",
                "sLengthMenu": "Vis _MENU_ rækker",
                "sLoadingRecords": "Henter data...",
                "sProcessing": "Loading...",
                "sSearch": "Søg på ordrenummer / kundenavn / email / telefonnr. ('Enter' for at søge):",
                "sSearchPlaceholder": "Søg her...",
                "sZeroRecords": "Ingen rækker matchede filter",
                "oPaginate": {
                    "sFirst": "Første",
                    "sLast": "Sidste",
                    "sNext": "Næste",
                    "sPrevious": "Forrige"
                }
            })
            .withOption('bFilter',false)
            .withOption('processing', false)
            .withOption('serverSide', true)
            .withPaginationType('simple_numbers')
            .withDisplayLength(10)
            .withOption('lengthMenu', [ 10, 25, 50, 75, 100,200,500])


        $scope.dtInstance = {};

        $scope.updateFilters = function(data)
        {
            data.search = {};
            data.search.value = $scope.searchText;
            data.search.regex = '';
            $location.search('search', encodeURIComponent($scope.searchText))

            data.filter = {};
            data.filter.DateFrom = $filter('date')(new Date($scope.filterDateFrom),'dd-MM-yyyy');
            $location.search('dateFrom',new Date($scope.filterDateFrom).getTime());

            data.filter.DateTo = $filter('date')(new Date($scope.filterDateTo),'dd-MM-yyyy');
            $location.search('dateTo', new Date($scope.filterDateTo).getTime());

            data.filter.StatusList = [];

            $scope.filterStatusList.filter(function(sts){
                if (sts.IsSelected){
                    data.filter.StatusList.push(sts.Value);
                }
            });

            var txt = '';
            for (var i =0; i<data.filter.StatusList.length;i++){
                txt += data.filter.StatusList[i]+',';
            }
            if (txt.indexOf(',', txt.length-1))
                txt = txt.substr(0,txt.length-1);

            $location.search('statusList', encodeURIComponent(txt));

            var queryObjects = $location.search();

            data.length = $scope.pagesize;
            data.start = queryObjects.start ? parseInt(queryObjects.start) : 0;

        }

        $scope.filterChanged = function()
        {
            $scope.dateFilterError = '';
            if (!$scope.filterDateFrom || !$scope.filterDateTo)
                return;

            var dateFrom = Date.parse($scope.filterDateFrom);
            var dateTo = Date.parse($scope.filterDateTo);

            if (dateTo < dateFrom)
            {
                $scope.dateFilterError = '\'Til\' må ikke være før \'Fra\'.';
                return;
            }

            $scope.reloadTable();
        }

        $scope.filterDate = function(ev){
            if(ev.keyCode === 13)
                $scope.filterChanged();
        }

        $scope.changeDateFilter = function (daysBack){
            var nowDate = new Date();

            var dateFrom = $scope.subtractDays(nowDate, daysBack);
            var dateNow = nowDate;

            $scope.filterDateFrom = dateFrom;
            $scope.filterDateTo = dateNow;

            $scope.filterChanged();
        }

        $scope.subtractDays = function (date, days) {
            var result = new Date(date);
            result.setDate(date.getDate() - days);
            return result;
        }

        $scope.customSearch = function(ev){
            if (ev.keyCode === 13)
                $scope.reloadTable();
        }

        $scope.reloadTable = function(){
            $("#loading").show();
            $scope.dtInstance.rerender();
        }

        $scope.setFilterFromPath = function()
        {
            var queryObjects = $location.search();
            if (!queryObjects)
                return;

            if (queryObjects.dateFrom) {
                var dateFrom = new Date(parseInt(queryObjects.dateFrom));
                $scope.filterDateFrom = new Date(dateFrom.getFullYear(),dateFrom.getMonth(),dateFrom.getDate());
            }

            if (queryObjects.dateTo) {
                var dateTo = new Date(parseInt(queryObjects.dateTo));
                $scope.filterDateTo = new Date(dateTo.getFullYear(),dateTo.getMonth(),dateTo.getDate());
            }

            if (queryObjects.statusList){
                var statusList = decodeURIComponent(queryObjects.statusList).split(',');
                for (var i =0; i<statusList.length;i++){
                    $scope.filterStatusList.filter(function(sts){
                        if (sts.Value === statusList[i])
                            sts.IsSelected = true;
                    });
                }
            }

            if (queryObjects.search)
                $scope.searchText = decodeURIComponent(queryObjects.search);

            if (queryObjects.page)
                $scope.page = parseInt(queryObjects.page);

            if (queryObjects.pagesize)
                $scope.pagesize = parseInt(queryObjects.pagesize);

            if (queryObjects.start)
                $scope.start = parseInt(queryObjects.start);
        }

        //https://l-lin.github.io/angular-datatables/archives/#!/bindAngularDirective

    }]);
})(window.angular);
