angular.module('site.controllers')
    .controller('userDetailsChangePasswordController', ['$scope', '$http', '$window', '$q', function($scope, $http, $window, $q) {

        // variables for this scope
        $scope.data = {
            userId : null, // will contain user profile data,
            model : {
                password: ''
            },
            formNames: {
                address: 'profileEditPasswordForm'
            },
            loading : {
                pendingChanges: false // when performing action
            },
            showValidationMessages: false
        };

        // initialization
        $scope.initialize = function(){

            // user id
            $scope.data.userId = $('#user-details-container').attr('data-user-id');
        };

        $scope.editPassword = function(){

            $scope.data.showValidationMessages = true;
            if ($scope[$scope.data.formNames.address].$invalid || $scope.data.loading.pendingChanges){
                console.log("Password form is invalid");
                return false;
            }

            // min length check
            if ($scope.data.model.password.length < 7)
            {
                return false;
            }

            $scope.data.loading.pendingChanges = true;
            $scope
                .changePassword()
                .then(function (resp) {
                    $scope.data.loading.pendingChanges = false;
                    var text = 'Adgangskode er blevet ændret til '+ $scope.data.model.password;
                    ShowSuccessToast(text);
                    setTimeout(function(){
                        alert(text);
                     }, 500);
                },function(resp) {
                    $scope.data.loading.pendingChanges = false;
                    ShowErrorToast("Fejl at skift adgangskode.");
            });
        };

        $scope.changePassword = function(){
            var deferred = $q.defer();
            $http
                .post('/usersapi/changePassword?userId='+$scope.data.userId,
                    {
                        password: $scope.data.model.password
                    })
                .then(function (resp) {
                    deferred.resolve(resp);
                }
                ,function(resp) {
                    deferred.reject(resp);
                    return deferred.promise;
                });
            return deferred.promise;
        }

        // toasts (to be moved to separate service)
        function ShowSuccessToast(text){
            ShowToast(text, 3600000, "#5cb85c");
        };

        function ShowErrorToast(text){
            ShowToast(text, 5000, "#d9534f");
        };

        function ShowToast(text, duration, color){
            // https://github.com/apvarun/toastify-js/blob/master/README.md
            Toastify({
                text: text,
                duration: duration,
                close: true,
                gravity: "bottom", // `top` or `bottom`
                positionLeft: false, // `true` or `false`
                backgroundColor: color,
                }).showToast();
        };
    }
]);
