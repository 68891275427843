(function (angular) {
    'use strict';
    angular.module('site.controllers', []);
    angular.module('site.services', []);
    angular.module('site.directives', []);
    angular.module('site.filters', []);

    angular.module('site', ['site.filters', 'site.services', 'site.directives', 'site.controllers','datatables','datatables.bootstrap'])
            .value('$anchorScroll', angular.noop)
            .constant('config', {
                hashPrefix: '!'
            })
        .config(['config', '$httpProvider', '$locationProvider', function (config, $httpProvider, $locationProvider) {
            $locationProvider.html5Mode(false).hashPrefix(config.hashPrefix);

            $httpProvider.interceptors.push(["$rootScope",function ($rootScope) {
                return {
                    'response': function (response)
                    {
                        if (response.data.StatusCode && (response.data.StatusCode === 500 ||  response.data.StatusCode === 400))
                        {
                            $rootScope.showMessage(false, response.data.ReasonPhrase);
                        }
                        // else TODO: Could be nice. Requires alot of changes to other requests.
                        // {
                        //     var message = response.data.message ? response.data.message : "Success.";
                        //     $rootScope.showMessage(true, message);
                        // }

                        return response;
                    },
                    'responseError': function (response)
                    {
                        if (response && (response.status === 500 || response.status === 400))
                            $rootScope.showMessage(response.status, response.data.ExceptionMessage);

                        return response;
                    }
                };
            }]);
        }])
        .run(["$rootScope","$timeout",function($rootScope,$timeout){
            $rootScope.showMessage = function(isSuccess, message){
                $rootScope.isSuccessVisible = false;
                $rootScope.isVisible = true;
                $rootScope.message = message;
                $timeout(function(){$rootScope.isVisible = false },10000)
            };
        }]);
})(window.angular);
