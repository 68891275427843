(function (angular) {
    'use strict';
    angular.module('site.services')
        .factory('informationservice', [ '$http','$q', function ($http, $q) {
            return {
                get : function (id) {
                    var api = id ? '/api/information/'+ id : '/api/information';
                    var deferred = $q.defer();
                    return $http
                        .get(api)
                        .then(function (response) {
                            deferred.resolve(response.data);
                            return deferred.promise;
                        }, function (response) {
                            deferred.reject(response);
                            return deferred.promise;
                        });
                },
                update : function (id, information) {
                    var api = '/api/information/'+ id;
                    var deferred = $q.defer();
                    return $http
                        .put(api, JSON.stringify(information))
                        .then(function (response) {
                            deferred.resolve(response.data);
                            return deferred.promise;
                        }, function (response) {
                            deferred.reject(response);
                            return deferred.promise;
                        });
                }
            }
        }]);
})(window.angular);

