angular.module('site.controllers')
    .controller('subscriptionListController', ['$scope', '$http', '$timeout', '$location', function ($scope, $http, $timeout, $location) {


        $scope.init = function () {
            var lastQuery = $location.path('/search');

            $scope.showInProgress = true;
            if (lastQuery && lastQuery.search && (lastQuery.search().q || lastQuery.search().page > 1)) {
                restoreState(lastQuery.search());
            } else {
                $scope.query = '';
                $scope.includeInactive = false;
                $scope.includeDeleted = false;
                $scope.page = 1;
                $scope.sortColumn = 'StartingDate';
                $scope.sortDirection = 'ASC';
                loadSubscriptions();
                $('#q').focus();
            }
        };

        $scope.nextPage = function (e) {
            e.preventDefault();
            $scope.page = $scope.page + 1;
            loadSubscriptions();
        }

        $scope.prevPage = function (e) {
            e.preventDefault();
            $scope.page = $scope.page - 1;
            loadSubscriptions();
        }

        $scope.changePage = function () {
            loadSubscriptions();
        }

        $scope.sortByColumn = function (e, field) {
            e.preventDefault();

            if (field === $scope.sortColumn) {
                if ($scope.sortDirection === 'ASC') {
                    $scope.sortDirection = 'DESC';
                }
                else {
                    $scope.sortDirection = 'ASC';
                }
            }
            else {
                $scope.sortColumn = field;
            }

            loadSubscriptions();
        }

        var searchPromise;
        $scope.searchInputChanged = function () {
            if (searchPromise)
                $timeout.cancel(searchPromise);

            searchPromise = $timeout(function () {
                loadSubscriptions();
            }, 400);
        }

        function loadSubscriptions() {
            $scope.pendingSearch = true;

            var url = '/subscriptionsapi/search?'
                + "term=" + $scope.query
                + "&includeInactive=" + $scope.includeInactive
                + "&includeDeleted=" + $scope.includeDeleted
                + "&page=" + $scope.page
                + "&sortColumn=" + $scope.sortColumn
                + "&sortDirection=" + $scope.sortDirection;

            $http.get(url)
                .then(function (resp) {
                    $scope.searchResult = resp.data;
                    $scope.page = resp.data.Page;
                    $scope.pendingSearch = false;
                    $scope.pageCount = resp.data.PageCount;
                    maintainState($scope);
                },function (response) {
                    alert("Kan ikke hente data.");
                    console.log("Kan ikke hente data.", response);
                });
        }

        function maintainState(scope) {
            $location.path('/search').search({
                q: scope.query,
                page: scope.page,
                sortColumn: scope.sortColumn,
                sortDirection: scope.sortDirection,
                includeInactive: scope.includeInactive,
                includeDeleted: scope.includeDeleted
            });
        }

        function restoreState(query) {
            console.log(query);
            $scope.query = query.q;
            $scope.includeInactive = query.includeInactive != 'false';
            $scope.includeDeleted = query.includeDeleted != 'false';
            $scope.page = query.page;
            $scope.sortColumn = query.sortColumn;
            $scope.sortDirection = query.sortDirection;
            loadSubscriptions();
        }

        $scope.goToUserDetails = function(sub){
            location.href = '/subscriptions/userprofile/'+ sub.ProfileId;
        }
        $scope.goToSubscriptionDetails = function(sub) {
            location.href = '/subscriptions/details/' + sub.Id;
        }
        $scope.goToProfileDetails = function(user){
            location.href = '/users/details/'+user.Id;
        }

    }]);
